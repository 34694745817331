import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { WithdrawService } from "../../../../Services/data/withdraw.service";
import { Observable, Subscription } from "rxjs";
import { BroadcastService } from "../../../../Services/broadcast/broadcast.service";
import { UserService } from "../../../../Services/auth/user.service";
import { MasterUserModel, UserSettings } from "../../../../Models/user.model";
import { AuthGuard } from "../../../../Services/guard";
import { Authorities } from "../../../../Services/data/constants";
import { AccountErrorStateMatcher } from "../../../../Services/form/custom_validator";
import { ToastaService, ToastOptions } from "ngx-toasta";
import {
  _filter,
  JsoneModel,
  StateGroup,
} from "../../../../Services/form/autocomplete.service";
import { JsonReaderService } from "../../../../Services/json-reader.service";
import { map, startWith } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-bank-transfer",
  templateUrl: "./bank-transfer-expansion.component.html",
  styleUrls: ["./bank-transfer-expansion.component.scss"],
})
export class BankTransferExpansionComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  bankTransferForm: UntypedFormGroup;
  routingNumberMinOrMaxLength = 9;
  routingNumber: string;
  accountNumber: string;
  accountNumberConfirm: string;
  accountType: string;
  selectAccountItems = [
    { value: "BUSINESS_CHECKING", field: "Business Checking" },
    { value: "BUSINESS_SAVINGS", field: "Business Savings" },
  ];
  selectCountryItems = [{ value: "UNITED_STATES", field: "United States" }];
  statesCities = [];
  states = [];
  statesOnly = [];
  stateGroups: StateGroup[];
  bankAccount: BankTransferModel;
  subscriptionBank: Subscription;
  userSetings = <MasterUserModel>{};
  beforeUserSettings: UserSettingsModel;
  afterUserSettings: UserSettingsModel;
  userDefault: BankTransferModel;
  matcher = new AccountErrorStateMatcher();
  textUnderInput = "The name must match the bank’s records";
  textForAddress = "The address must match the bank’s records";
  authorities = Authorities;
  stateGroupOptions: Observable<StateGroup[]>;
  state: string;
  abreviation: any;
  errorMessage: string;
  objectKeys = Object.keys;
  private subscriptionJson: Subscription;
  private subscriptionJsonState: Subscription;
  private subscriptionCityJson: Subscription;
  filteredStates: Observable<JsoneModel[]>;
  isCityLoading = false;
  toastSuccesUpdated: ToastOptions = {
    title: "Bank details",
    msg: "Your changes were updated successfully!",
    showClose: true,
    timeout: 5000,
    theme: "material",
  };
  @Input() isOpen: boolean;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onOpen: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateInfo: EventEmitter<any> = new EventEmitter();
  @Output() onLoading: EventEmitter<any> = new EventEmitter();

  constructor(
    private withdrawService: WithdrawService,
    private broadcastService: BroadcastService,
    private jsonReader: JsonReaderService,
    public authGuard: AuthGuard,
    public userService: UserService,
    private toastyService: ToastaService
  ) {}

  ngOnInit(): void {
    this.getLoggedUserInfo();
    this.subscribeBank();
  }

  formGroup() {
    this.bankTransferForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl(this.userSetings.organization.name, [
          Validators.required,
        ]),
        routingNumber: new UntypedFormControl(this.routingNumber, [
          Validators.required,
          Validators.minLength(this.routingNumberMinOrMaxLength),
          Validators.maxLength(this.routingNumberMinOrMaxLength),
        ]),
        accountNumber: new UntypedFormControl(this.accountNumber, [
          Validators.required,
        ]),
        accountNumberConfirm: new UntypedFormControl(this.accountNumber, [
          Validators.required,
        ]),
        accountType: new UntypedFormControl(this.accountType, [
          Validators.required,
        ]),
        address: new UntypedFormGroup({
          country: new UntypedFormControl("United States", [
            Validators.required,
          ]),
          address1: new UntypedFormControl("", [Validators.required]),
          address2: new UntypedFormControl(""),
          city: new UntypedFormControl("", [Validators.required]),
          region: new UntypedFormControl("", [Validators.required]),
          postalCode: new UntypedFormControl("", [Validators.required]),
        }),
      },
      this.checkAccount
    );
  }

  get routingNumberInput() {
    return this.bankTransferForm.get("routingNumber");
  }

  get accountNumberInput() {
    return this.bankTransferForm.get("accountNumber");
  }

  get accountNumberConfirmInput() {
    return this.bankTransferForm.get("accountNumberConfirm");
  }

  get accountTypeInput() {
    return this.bankTransferForm.get("accountType");
  }

  get organizationNameInput() {
    return this.bankTransferForm.get("name");
  }

  get countryInput() {
    return this.bankTransferForm.get("address.country");
  }

  get address1Input() {
    return this.bankTransferForm.get("address.address1");
  }

  get address2Input() {
    return this.bankTransferForm.get("address.address2");
  }

  get cityInput() {
    return this.bankTransferForm.get("address.city");
  }

  get regionInput() {
    return this.bankTransferForm.get("address.region");
  }

  get postalCodeInput() {
    return this.bankTransferForm.get("address.postalCode");
  }

  getLoggedUserInfo() {
    this.userService.getLoggedUserProfile().subscribe(
      (user: MasterUserModel) => {
        this.userSetings = user;
        this.formGroup();
        this.getBankAccountDetail();
        this.getAllCities();
      },
      (errorResponse: HttpErrorResponse) => {
        // Todo: Show some kind of error
      }
    );
  }

  getAllCities() {
    this.subscriptionJson = this.jsonReader
      .getJSON("data/states-cities.json")
      .subscribe((data) => {
        this.statesCities = data;
        this.states = this.objectKeys(data);
      });
    this.subscriptionJsonState = this.jsonReader
      .getJSON("data/states_hash.json")
      .subscribe((data) => {
        this.statesOnly = data;
      });
    this.subscriptionCityJson = this.jsonReader
      .getJSON("data/states-cities-obj.json")
      .subscribe((data: StateGroup[]) => {
        this.stateGroups = data;
      });
    this.stateGroupOptions = this.cityInput.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterGroup(value))
    );
    this.filteredStates = this.regionInput.valueChanges.pipe(
      startWith(""),
      map((state) =>
        state ? this.filterGroupStateOnly(state) : this.statesOnly.slice()
      )
    );
  }

  checkAccount(group: UntypedFormGroup) {
    const account = group.controls.accountNumber.value;
    const confirmAccount = group.controls.accountNumberConfirm.value;
    return account === confirmAccount ? null : { accountMismatch: true };
  }

  submitModal() {
    if (this.bankTransferForm.valid) {
      this.beforeUserSettings = JSON.parse(
        JSON.stringify(this.bankTransferForm.value)
      );
      this.inputChange("organizationNameInput", "name");
      this.afterUserSettings = JSON.parse(
        JSON.stringify(this.bankTransferForm.value)
      );
      const isChangeOrganizationName =
        this.beforeUserSettings.name === this.afterUserSettings.name;
      this.onSave.emit({
        user: this.bankDetails,
        isNotChange: this.changeName(isChangeOrganizationName),
      });
    }
  }

  get bankDetails() {
    return {
      name: this.organizationNameInput.value,
      routingNumber: this.bankAccount.routingNumber,
      accountNumber: this.bankAccount.accountNumber,
      accountNumberConfirm:
        this.bankAccount.accountNumberConfirm || this.bankAccount.accountNumber,
      id: this.bankAccount.id,
      accountType: this.accountTypeInput.value,
      address: {
        country: "US",
        address1: this.address1Input.value,
        address2: this.address2Input.value ? this.address2Input.value : null,
        city: this.capitalize(this.cityInput.value.toString().toLowerCase()),
        region: this.abreviation || this.bankAccount.address.region,
        postalCode: this.postalCodeInput.value,
      },
    };
  }

  capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  changeName(organizationName) {
    const isChangeOrganizationName =
      this.userDefault && this.userDefault.name
        ? this.userDefault.name === this.userSetings.organization.name
        : organizationName;
    return {
      isChangeOrganizationName,
    };
  }

  getEmailOrPhoneValidate(parameter: string, typeOfError: string) {
    const formGet = this.bankTransferForm.get(parameter);
    if (formGet.value) {
      formGet.markAsTouched();
    }
    return formGet.invalid && formGet.touched && formGet.errors[typeOfError];
  }

  getErrorMessage() {
    this.errorMessage =
      "Please double-check the address you've entered. It must correspond to the official/legal mailing address of yours.";
    setTimeout(() => {
      this.errorMessage = "";
    }, 10000);
  }

  addBankAccount() {
    this.isLoading = true;
    this.getIsLoading(this.isLoading);
    this.withdrawService.setWithdrawBank(this.bankDetails).subscribe(
      (res: BankTransferModel) => {
        this.updateInfo(res);
        this.onOpen.emit(false);
        this.userDefault = JSON.parse(JSON.stringify(res));
        this.isLoading = false;
        this.toastyService.success(this.toastSuccesUpdated);
        this.getIsLoading(this.isLoading);
      },
      () => {
        this.isLoading = false;
        this.getErrorMessage();
        this.scrollToTop();
        const toastErrorUpdated: ToastOptions = {
          title: "Error",
          msg: "Something went wrong!",
          showClose: true,
          timeout: 5000,
          theme: "material",
        };
        this.toastyService.error(toastErrorUpdated);
        this.getIsLoading(this.isLoading);
        // this.updateForm(this.userDefault);
      }
    );
  }

  scrollToTop() {
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: "smooth",
    });
  }

  getIsLoading(isLoading) {
    this.onLoading.emit(isLoading);
  }

  getState(state) {
    this.state = state;
    this.regionInput.setValue(this.state);
    this.getAbbreviation(this.state);
  }

  getAbbreviation(state) {
    if (!state) {
      return (this.abreviation = state);
    }
    this.subscriptionJson = this.jsonReader
      .getJSON("data/states_hash.json")
      .subscribe((data: JsoneModel[]) => {
        for (const index in data) {
          if (data[index].name === state) {
            this.abreviation = data[index].abbreviation;
          }
        }
        return this.abreviation;
      });
  }

  filterGroup(value): StateGroup[] {
    if (value && this.isCityLoading) {
      return this.stateGroups
        .map((group) => ({
          state: group.state,
          city: _filter(group.city, value),
        }))
        .filter((group) => group.city.length > 0);
    }
  }

  filterGroupStateOnly(value): JsoneModel[] {
    const filterValue = value.toLowerCase();
    return this.statesOnly.filter(
      (state) => state.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  inputCityChange(event) {
    return (this.isCityLoading = event?.length > 1);
  }

  asteriskNumber(formControlName: string, numChar: number) {
    return formControlName
      .split("")
      .map((char, index) => {
        if (index >= formControlName.length - numChar) {
          return char;
        } else {
          return "*";
        }
      })
      .join("");
  }

  inputChange(formControlNameInput: string, formControlName: string) {
    if (
      this[formControlNameInput] &&
      this[formControlNameInput].value &&
      this[formControlNameInput].value.includes("*")
    ) {
      this[formControlNameInput].setValue("");
    }
    this.bankAccount[formControlName] =
      this[formControlNameInput] && this[formControlNameInput].value
        ? this[formControlNameInput].value
        : null;
  }

  getBankAccountDetail() {
    this.withdrawService.getWithdrawBank().subscribe(
      (res: BankTransferModel) => {
        const responseBankTransfer = res;
        if (!res.id) {
          responseBankTransfer.name = this.userSetings.organization.name;
        }
        this.updateInfo(responseBankTransfer);
        this.userDefault = Object.assign({}, res);
        this.isLoading = false;
        this.getIsLoading(this.isLoading);
      },
      () => {
        this.bankAccount = this.resetAccount();
        this.isLoading = false;
        this.getIsLoading(this.isLoading);
      }
    );
  }

  getBankAccount(account: string, numChar?: number) {
    if (account.includes("accountType")) {
      return this.bankAccount && this.bankAccount[account]
        ? this.bankAccount[account]
        : "";
    }
    return this.bankAccount && this.bankAccount[account]
      ? this.asteriskNumber(this.bankAccount[account], numChar)
      : "";
  }

  subscribeBank() {
    this.subscriptionBank = this.broadcastService.events.subscribe(
      (event: any) => {
        if (event.type === "DELETE_BANK_DETAILS") {
          this.routingNumber = "";
          this.accountNumber = "";
          this.accountNumberConfirm = "";
          this.accountType = "";
          this.bankTransferForm.reset();
          this.countryInput.setValue("United States");
          this.userDefault = Object.assign({}, this.resetAccount());
        }
        if (event.type === "USER_BY_DEFAULT") {
          this.updateForm(
            this.userDefault ? this.userDefault : this.beforeUserSettings
          );
          this.bankTransferForm.markAsUntouched();
        }
        if (event.type === "UPDATE_USER") {
          this.addBankAccount();
        }
      }
    );
  }

  updateInfo(res) {
    this.bankAccount =
      res !== false ? Object.assign({}, res) : this.resetAccount();
    this.routingNumber = this.getBankAccount("routingNumber", 2);
    this.accountNumber = this.getBankAccount("accountNumber", 4);
    this.accountNumberConfirm = this.getBankAccount("accountNumber", 4);
    this.accountType = this.getBankAccount("accountType");
    this.updateForm(res);
    this.onUpdateInfo.emit(this.accountNumber);
  }

  isClosedExpansion() {
    this.updateForm(
      this.userDefault ? this.userDefault : this.beforeUserSettings
    );
    this.bankTransferForm.markAsUntouched();
  }

  updateForm(res: BankTransferModel) {
    if (res) {
      if (this.organizationNameInput) {
        this.organizationNameInput.setValue(res.name);
      }
      this.routingNumberInput.setValue(this.routingNumber);
      this.accountNumberInput.setValue(this.accountNumber);
      this.accountNumberConfirmInput.setValue(this.accountNumberConfirm);
      this.accountTypeInput.setValue(res ? res.accountType : this.accountType);
      this.address1Input.setValue(res.address?.address1);
      this.address2Input.setValue(res.address?.address2);
      this.cityInput.setValue(res.address?.city);
      this.regionInput.setValue(res.address?.region);
      this.postalCodeInput.setValue(res.address?.postalCode);
    }
  }

  resetAccount(): BankTransferModel {
    return {
      name: this.userDefault ? this.userDefault?.name : "",
      routingNumber: "",
      accountNumber: "",
      accountNumberConfirm: "",
      accountType: "",
      address: {
        country: "",
        address1: "",
        address2: "",
        city: "",
        region: "",
        postalCode: "",
      },
    };
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    if (this.subscriptionJson) {
      this.subscriptionJson.unsubscribe();
    }
    if (this.subscriptionJsonState) {
      this.subscriptionJsonState.unsubscribe();
    }
    if (this.subscriptionCityJson) {
      this.subscriptionCityJson.unsubscribe();
    }
    if (this.subscriptionBank) {
      this.subscriptionBank.unsubscribe();
    }
  }
}

export interface BankTransferModel {
  routingNumber?: string;
  accountNumber?: string;
  accountType?: string;
  firstName?: string;
  id?: string;
  accountNumberConfirm?: string;
  lastName?: string;
  organizationName?: string;
  name?: string;
  address: {
    country?: string;
    address1?: string;
    address2?: string;
    city?: string;
    region?: string;
    postalCode?: string;
  };
}

export interface UserSettingsModel {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  organizationName?: string;
  name?: string;
  organizationId?: string;
  userSettings?: UserSettings;
  address: {
    country?: string;
    address1?: string;
    address2?: string;
    city?: string;
    region?: string;
    postalCode?: string;
  };
}
