import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'prettyJson'
})
export class PrettyJsonPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(val) {
    const jsonString = JSON.stringify(val, null, 2);
    const formattedHtml = jsonString
      .replace(/ /g, '&nbsp;') // Replace all spaces
      .replace(/\n/g, '<br/>'); // Replace all line breaks

    return this.sanitizer.bypassSecurityTrustHtml(formattedHtml);
  }
}
