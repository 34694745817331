import { TokenService } from "../auth/token.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DocsService {
  constructor(private token: TokenService) {}

  openDocumentation(event) {
    const url = `https://loyalize.com/docs/api/`;
    const docsWindow = window.open(url, "_blank");

    const sendToken = setInterval(() => {
      const token = this.token.getToken();
      docsWindow!.postMessage(token, "https://loyalize.com/docs/api/");
    }, 200);
    event.preventDefault();
    setTimeout(() => {
      clearInterval(sendToken);
    }, 6500);
  }

  removeURLParameter(url, parameter) {
    const urlParts = url.split("?");
    if (urlParts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + "=";
      const parts = urlParts[1].split(/[&;]/g);
      for (let i = parts.length; i-- > 0; ) {
        if (parts[i].lastIndexOf(prefix, 0) !== -1) {
          parts.splice(i, 1);
        }
      }
      return urlParts[0] + (parts.length > 0 ? "?" + parts.join("&") : "");
    }
    return url;
  }
}
