import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {FormService} from '../../../../Services/form/form';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../Services/guard';
import {AuthService} from '../../../../Services/auth';
import {UserService} from '../../../../Services/auth/user.service';
import {MasterUserModel, UserModel} from '../../../../Models/user.model';
import {HttpErrorResponse} from '@angular/common/http';
import {TokenInterface} from '../../../../Pages/UserAccount/ApiPartner/ApiKey/ApiPartner.component';
import {BroadcastService} from '../../../../Services/broadcast/broadcast.service';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  checked = true;
  userId = '';
  parentId = '';
  subscriptionQureryParam: Subscription;
  loggedInSubscription: Subscription;
  getLoggedUserInfoSubscription: Subscription;
  valueChangesSubscription: Subscription;
  isLoading = false;
  formErrors = {
    username: '',
    password: '',
  };
  passHide = true;
  confirmPassHide = true;
  isTokenValidated = null;
  badCredentials = false;
  messageErrors: TextError;

  constructor(
    public formService: FormService,
    private router: Router,
    private route: ActivatedRoute,
    public authGuard: AuthGuard,
    private authService: AuthService,
    public userService: UserService,
    private broadcastService: BroadcastService,
  ) {
    this.autoOpenDialogLogin();
  }


  ngOnInit() {
    this.getUrl();
    this.buildForm();
    this.getLoggedInSubscription();

  }

  public buildForm() {
    const username = '';
    const password = '';

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(username, [Validators.required, Validators.email]),
      password: new UntypedFormControl(password, [Validators.required])
    });

  this.valueChangesSubscription =  this.loginForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.loginForm, this.formErrors, true);
      this.badCredentials = false;
    });
  }

  get usernameInput() {
    return this.loginForm.get('username');
  }

  get passwordInput() {
    return this.loginForm.get('password');
  }

  get confirmNewPasswordInput() {
    return this.loginForm.get('confirmNewPassword');
  }

  OnSimpleLogin() {
    this.isLoading = true;
    this.messageErrors = null;
    this.authService.login(this.loginForm.value.username, this.loginForm.value.password, this.checked).then((errorResponse: any) => {
      if (errorResponse?.error_description === 'Loyalize Organization is not activated') {
        this.messageErrors = {
          firstText: 'Account not yet activated.',
          secondText: 'to receive a new activation email.',
          linkText: 'Click here'
        };
        this.badCredentials = true;
        this.isLoading = false;
      } else if (errorResponse !== 'ok' && errorResponse?.error_description !== 'Account is not activated') {
        this.messageErrors = {
          firstText: 'Authentication failed. Please check (and re-enter) your email and password.',
          secondText: '',
          linkText: ''
        };
        this.badCredentials = true;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  getLoggedInSubscription() {
    this.loggedInSubscription = this.broadcastService.events.subscribe((event: any) => {
      if (event.type === 'LOGGED_IN') {
        this.getLoggedUserInfo();
      }
      if (event.type === 'LOGGED_OUT') {
        localStorage.removeItem('apiu_token');
      }
    });
  }

  getLoggedUserInfo() {
    this.getLoggedUserInfoSubscription = this.userService.getLoggedUserProfile()
    .subscribe((user: MasterUserModel) => {
      this.userService.masterProfileChange(user);
      if (this.authGuard.isAuthoritiesApiPartner()) {
        localStorage.setItem('organizationName', user.organization.name);
        this.router.navigate(['/account/loyalize-user/transactions']).then(() => {
          this.broadcastService.dispathEvent({type: 'ACCESS_TOKEN_LOGGED_IN'});
        });
      }
    }, ((errorResponse: HttpErrorResponse) => {
      // Todo: Show some kind of error
    }));
  }

  getPersonalAccessToken(user: UserModel) {
    this.userService.getPersonalAccessToken(user.id).subscribe((resp: TokenInterface) => {
      localStorage.setItem('apiu_token', resp.token);
      localStorage.setItem('rememberMe', 'true');
      if (this.authGuard.isAuthoritiesApiPartner()) {
        this.userService.userProfileChange(user);
        localStorage.setItem('organizationName', user.organizationName);
        this.isLoading = false;
        this.router.navigate(['/account/loyalize-user/transactions']).then(() => {
          this.broadcastService.dispathEvent({type: 'ACCESS_TOKEN_LOGGED_IN'});
        });
      }
    }, () => {

    });
  }

  getInfoWithToken(token: string) {
    this.userService.getInfoWithToken(token).subscribe((resp: IUserFromToken) => {
      this.isLoading = false;
      this.userId = resp.id;
      this.usernameInput.setValue(resp.email);
    }, () => {
      this.isLoading = false;
    });
  }

  submitUser() {
      this.OnSimpleLogin();
    }

  autoOpenDialogLogin() {
    if (!this.authGuard.isAuthenticated()) {
      this.subscriptionQureryParam = this.route.queryParamMap.subscribe(params => {
        this.parentId = params.get('parentId');
      });
    }
  }

  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
        ? null
        : {passwordMismatch: true};
    };
  }

  deleteText() {
    this.messageErrors = null;
  }

  getUrl() {
    const routerUrl = this.router.url.toLocaleString().trim().split('?')[0];
    const navigate = routerUrl === '/components/header' ? '/' : routerUrl;
    localStorage.setItem('navigate', navigate);
  }

  resendEmail() {
    this.authService.resendActivation(this.loginForm.get('username').value).subscribe(res => {
    });
  }

  check(event) {
    this.checked = event;
  }

  getPasswordValidate(parameter: string, typeOfError: string, parameter2?: string) {
    const formGet = this.loginForm.get(parameter);
    let formGet2;
    if (parameter2) {
      formGet2 = this.loginForm.get(parameter2);
    }
    if (formGet.value) {
      formGet.markAsTouched();
    }
    if (parameter2 && formGet2 && formGet2.value) {
      formGet.markAsTouched();
    }
    if (parameter2 && formGet2 && formGet2.value) {
      formGet2.markAsTouched();
    }
    return formGet.invalid && formGet.touched && formGet.errors[typeOfError];
  }

  ngOnDestroy(): void {
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
    if (this.getLoggedUserInfoSubscription) {
      this.getLoggedUserInfoSubscription.unsubscribe();
    }
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

}

export interface TextError {
  firstText: string;
  secondText: string;
  linkText: string;

}

export interface IUserFromToken {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export interface IUserSetPassw {
  newPassword: string;
  confirmNewPassword: string;
}
