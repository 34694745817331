export const config = {
  firebaseConfig: {
    'apiKey': 'AIzaSyCH79aD8RUB5s_I9FJlRh8J_eh841OACYo',
    'authDomain': 'monitoringsystem-d7a51.firebaseapp.com',
    'databaseURL': 'https://monitoringsystem-d7a51.firebaseio.com',
    'projectId': 'monitoringsystem-d7a51',
    'storageBucket': 'monitoringsystem-d7a51.appspot.com',
    'messagingSenderId': '180071302188',
    'appId': '1:180071302188:web:b04624815a7d61c47e7d89',
    'measurementId': 'G-V8RF8PRZDL'
  },
  firebaseCredentials: {
    'email': 'monitoring-user@gmail.com',
    'password': 'matrix13'
  }
};
