import {LoaderComponent} from './Loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';


@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    LoaderComponent
  ],
  exports: [
    LoaderComponent
  ],
})
export class LoaderModule {
}
