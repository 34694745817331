import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {expand, map} from 'rxjs/operators';

@Injectable()
export class ImageUtils {

  data: FileList;
  compressedImages = [];

  compress(file: File): Observable<any> {
    const width = 300; // For scaling relative to width
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return Observable.create(observer => {
      reader.onload = ev => {
        const img = new Image();
        img.src = (ev.target as any).result;
        (img.onload = () => {
          if (img.width <= width) {
            return observer.next(
              file
            );
          }
          const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
          const scaleFactor = width / img.width;
          elem.width = width;
          elem.height = img.height * scaleFactor;
          const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);

          ctx.canvas.toBlob(
            blob => {
              observer.next(
                new File([blob], file.name, {
                  type: 'image/png',
                  lastModified: Date.now(),
                }),
              );
            },
            'image/png',
            1,
          );
        });
        (reader.onerror = error => observer.error(error));
      };
    });
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compress(image).pipe(
      map(response => {

        // Code block after completing each compression
        this.compressedImages.push(response);
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  };

// imageCompressingProcess files for upload
  public imageCompressingProcess(event) {

    this.compressedImages = [];

    this.data = event.target.files;
    const compress = this.recursiveCompress(this.data[0], 0, this.data).pipe(
      expand(res => {
        return res.index > res.array.length - 1
          ? EMPTY
          : this.recursiveCompress(this.data[res.index], res.index, this.data);
      }),
    );
    return compress;
  }

  getImageUrl(imageUrl): string {
    if (!imageUrl) {
      return null;
    }

    if (imageUrl && imageUrl.indexOf('pepperjamnetwork.com') !== -1) {
      return 'https://'.concat(imageUrl);
    }

    if (imageUrl.startsWith('http') || imageUrl.startsWith('https')) {
      return imageUrl;
    }

    if (imageUrl.startsWith('../')) {
      return imageUrl;
    }

    return 'data:image/png;base64,'.concat(imageUrl);
  }
}
