import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FlexLayoutModule } from "@angular/flex-layout";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { ToastaModule } from "ngx-toasta";
import { BidiModule } from "@angular/cdk/bidi";
import { AppRoutes } from "./app-routing";
import { GlobalModule } from "./GlobalEmbryo/Global.module";
import { MenuItems } from "./Core/menu/menu-items/menu-items";
import { SideNavService } from "./Services/global/side-nav.service";
import { AppComponent } from "./app.component";
import { MainComponent } from "./Main/Main.component";
import { NewHeaderComponent } from "./Layouts/Header/NewHeader/NewHeader.component";
import { MenuComponent } from "./Layouts/Menu/Menu/Menu.component";
import { NotFoundComponent } from "./Pages/NotFound/NotFound.component";
import { RequestInterceptorService } from "./Services/interceptors/request.interceptor";
import { AuthGuard } from "./Services/guard";
import { DataService } from "./Services/data/data.service";
import { FirebaseService } from "./Services/firebase/firebase.service";
import { ImageUtils } from "./Utils/image.utils";
import { UserService } from "./Services/auth/user.service";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { PipeModule } from "./Services/pipe/pipe.module";
import { TitleService } from "./Services/title/title.service";
import { CustomPreloadStrategyService } from "./Services/guard/custom-preload-strategy.service";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatInputModule } from "@angular/material/input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRadioModule } from "@angular/material/radio";
import { MatChipsModule } from "@angular/material/chips";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatMenuModule } from "@angular/material/menu";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { config } from "./Services/firebase/config";
import { ForgotPasswordComponent } from "./Pages/ForgotPassword/forgot-password.component";
import { UserAccountModule } from "./Pages/UserAccount/UserAccount.module";
import { LoaderModule } from "./Components/loader-screen/Loader.module";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NewHeaderComponent,
    MenuComponent,
    ForgotPasswordComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "embryo-seo-pre" }),
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: "reload",
      preloadingStrategy: CustomPreloadStrategyService,
      relativeLinkResolution: "legacy",
    }),
    GlobalModule,
    PipeModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarRouterModule,
    MatAutocompleteModule,
    LoadingBarModule,
    UserAccountModule,
    ToastaModule.forRoot(),
    BidiModule,
    LoaderModule,
    AngularFireModule.initializeApp(config.firebaseConfig),
    AngularFirestoreModule,
  ],
  providers: [
    AuthGuard,
    TitleService,
    CustomPreloadStrategyService,
    MenuItems,
    DataService,
    FirebaseService,
    ImageUtils,
    UserService,
    SideNavService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
