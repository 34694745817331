
<div [dir]="isRtl" (scroll)="onScrollEvent($event)">
  <mat-sidenav-container>
    <mat-sidenav #sidenav [(opened)]="sideNavService.sidenavOpen" class="sidebar-area" mode="over">
      <mat-nav-list>
        <div class="close-btn-sidebar">
          <a href="javascript:void(0)" (click)="sideNavService.sidenavOpen = false">
            <i class="material-icons">
              close
            </i>
          </a>
        </div>
        <app-embryo-menu [mobile]="true"></app-embryo-menu>
      </mat-nav-list>
    </mat-sidenav>
    <ngx-toasta></ngx-toasta>
    <app-header></app-header>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="!isLoggedIn" [isLoggedIn]="true"></app-footer>
  </mat-sidenav-container>
</div>
