import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreToSpace'
})
export class UnderscoreToSpacePipe implements PipeTransform {

    transform(value: string): string {
        if (value === 'NON_COMMISSIONABLE') {
            return 'Non-commissionable';
        }

        const words = value.split('_');
        const transformedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return transformedWords.join(' ');
    }

}
