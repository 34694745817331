<div class="customer-container image-container" >
<div class="sign-in-class">
<h4  class="text-center font-bold text-responsive"> Forgot Your Password?</h4>
<p  class="text-center sub-title-responsive"> We'll send you an email with instruction.</p>
<div>
  <div class="separator">
  </div>
    <app-dismiss-alert
      *ngIf="errorText"
      (deleteText)="deleteText()"
      [ngStyle]="{textAlign: 'center'}"
      (clickOnLink)="clickOnLink($event)"
      [text]="errorText">
    </app-dismiss-alert>
    <form [formGroup]="resetForm">
      <mat-card class="mt-3 p-0 box-shadow-none">
        <div class="list">
          <div class="mb-3">
            <mat-form-field color="#474477" class="w-100 mb-3">
              <input matInput formControlName="email" placeholder="Email" required
                     autocomplete="false">
              <mat-error *ngIf="getEmailOrPhoneValidate('email', 'required')">This field should
                not be empty.
              </mat-error>
              <mat-error *ngIf="getEmailOrPhoneValidate('email', 'email')">Please enter a valid email address.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
  <div class="modal-footer pb-3">
    <div class="modal-footer-button mb-3">
      <button type="submit" [disabled]="!resetForm.valid || isLoading" class="moreBrands"
              (click)="resetForm.valid ? resetPassword() : ''">RESET PASSWORD
      </button>
      <div *ngIf="isLoading"  fxLayout="row" fxLayoutAlign="center"
           class="spinner align-spinner">
        <app-loader style="margin-left: auto;margin-right: auto;"></app-loader>
      </div>
    </div>
  </div>
</div>
</div>
