<div class="customer-container image-container">
  <div class="info-box">
    <img src="assets/svg/info.svg" alt="" class="info-img" />
    <div>
      API Docs are available to registered users only.
      <a
        class="contact-us-link"
        href="https://loyalize.com/#contact-us"
        target="_blank"
        >Contact us</a
      >
       to become one.
    </div>
  </div>
  <div class="sign-in-class">
    <h4 class="text-center font-bold text-responsive">
      Sign Into Your Account
    </h4>

    <div>
      <app-dismiss-alert
        *ngIf="messageErrors"
        (clickOnLink)="resendEmail()"
        (deleteText)="deleteText()"
        [text]="messageErrors"
      ></app-dismiss-alert>
      <form [formGroup]="loginForm" (keyup.enter)="submitBtn.click()">
        <div class="margin-input">
          <mat-form-field color="#474477" class="w-100">
            <input
              matInput
              formControlName="username"
              [readonly]="isLoading"
              autocomplete="username"
              placeholder="Email"
              required
            />
            <mat-error
              align="start"
              *ngIf="
                loginForm.get('username').invalid &&
                loginForm.get('username').touched
              "
              class="form__error"
            >
              This is a required field.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="margin-input">
          <mat-form-field color="#474477" class="w-100">
            <input
              matInput
              [type]="passHide ? 'password' : 'text'"
              formControlName="password"
              placeholder="Password"
              [readonly]="isLoading"
              autocomplete="current-password"
              required
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="passHide = !passHide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="passHide"
            >
              <mat-icon>
                <img
                  *ngIf="passHide"
                  style="height: 18px"
                  alt="visibility-off"
                  class="disabled-color"
                  src="../../../../../assets/svg/home/visibility-off.svg" />
                <img
                  *ngIf="!passHide"
                  style="height: 18px"
                  class="accent-color'"
                  alt="visibility-on"
                  src="../../../../../assets/svg/home/visibility-on.svg"
              /></mat-icon>
            </button>
            <mat-error *ngIf="getPasswordValidate('password', 'required')">
              This is a required field.
            </mat-error>
            <mat-error *ngIf="getPasswordValidate('password', 'minlength')"
              >Password too short. Minimum required length: 6 characters.
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="loginForm.get('confirmNewPassword')"
            class="w-100 mb-4"
          >
            <input
              [type]="confirmPassHide ? 'password' : 'text'"
              matInput
              placeholder="Retype Password"
              autocomplete="new-password"
              [readonly]="isLoading"
              formControlName="confirmNewPassword"
              required
            />
            <button
              mat-icon-button
              matSuffix
              (click)="confirmPassHide = !confirmPassHide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="confirmPassHide"
            >
              <mat-icon
                [ngClass]="{
                  'accent-color': !confirmPassHide,
                  'disabled-color': confirmPassHide
                }"
              >
                <img
                  *ngIf="confirmPassHide"
                  alt="visibility-off"
                  src="../../../../../assets/svg/home/visibility-off.svg" />
                <img
                  *ngIf="!confirmPassHide"
                  class="logo display-img"
                  alt="visibility-on"
                  src="../../../../../assets/svg/home/visibility-on.svg"
              /></mat-icon>
            </button>
            <mat-error
              *ngIf="
                getPasswordValidate(
                  'confirmNewPassword',
                  'required',
                  'newPassword'
                )
              "
            >
              This is a required field.
            </mat-error>
            <mat-error
              *ngIf="getPasswordValidate('confirmNewPassword', 'minlength')"
              >Password too short. Minimum required length: 6 characters.
            </mat-error>
            <mat-error
              *ngIf="
                getPasswordValidate(
                  'confirmNewPassword',
                  'passwordMismatch',
                  'newPassword'
                )
              "
            >
              Passwords do not match
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="modal-footer pb-3">
      <div class="modal-footer-button mb-3">
        <button
          #submitBtn
          type="submit"
          [disabled]="!loginForm.valid || isLoading"
          class="moreBrands"
          (click)="loginForm.valid ? submitUser() : ''"
        >
          Sign In
        </button>
        <div
          *ngIf="isLoading"
          fxLayout="row"
          fxLayoutAlign="center"
          class="spinner align-spinner"
        >
          <mat-spinner
            style="margin-left: auto; margin-right: auto"
            [diameter]="20"
          ></mat-spinner>
        </div>
      </div>
      <app-checkbox-remember></app-checkbox-remember>
    </div>
  </div>
</div>
