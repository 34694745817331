export interface StateGroup {
  state: string;
  city: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

export interface JsoneModel {
  name: string;
  abbreviation: string;
}
