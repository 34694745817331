import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { Injectable } from '@angular/core';

// setup simple regex for white listed characters
const validCharacters = /[^\s\w,.:&\/()+%'`@-]/;

// create your class that extends the angular validator class
@Injectable()
export class CustomValidators extends Validators {

  // create a static method for your validation
  static validateCharacters(control: UntypedFormControl) {

    // first check if the control has a value
    if (control.value && control.value.length > 0) {

      // match the control value against the regular expression
      const matches = control.value.match(validCharacters);

      // if there are matches return an object, else return null.
      return matches && matches.length ? {invalid_characters: matches} : null;
    } else {
      return null;
    }
  }
}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.parent.errors && control.parent.errors.passwordMismatch;
  }
}
export class AccountErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.parent.errors && control.parent.errors.accountMismatch;
  }
}
