<div class="customRow" *ngIf="isTokenValidated; else isBroken">
  <div class="content">
    <div class="close-button" (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </div>
    <div>
      <div mat-dialog-content>
        <h4 class="modal-header">Loyalize User Registration</h4>
        <form [formGroup]="organizationForm">
          <div class="mt-3 p-0">
            <div class="list">
              <mat-form-field class="w-100">
                <input
                  matInput
                  type="email"
                  placeholder="First Name"
                  formControlName="firstName"
                  required
                />
                <mat-error *ngIf="getPasswordValidate('firstName', 'required')">
                  This is a required field.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  required
                />
              </mat-form-field>
              <mat-form-field class="w-100">
                <input
                  matInput
                  type="email"
                  placeholder="Email"
                  formControlName="email"
                  style="color: darkgray"
                  readonly
                />
              </mat-form-field>
              <mat-form-field class="w-100">
                <input
                  matInput
                  placeholder="Organization Name"
                  formControlName="organizationName"
                  style="color: darkgray"
                  readonly
                />
                <mat-error
                  *ngIf="getPasswordValidate('organizationName', 'required')"
                >
                  This is a required field.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <input
                  matInput
                  type="password"
                  placeholder="Password"
                  formControlName="password"
                  [type]="newPassHide ? 'password' : 'text'"
                  required
                  autocomplete="password"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="newPassHide = !newPassHide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="newPassHide"
                >
                  <mat-icon
                    [ngClass]="{
                      '#474477': !newPassHide,
                      'disabled-color': newPassHide
                    }"
                    >{{
                      newPassHide ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </button>
                <mat-error *ngIf="getPasswordValidate('password', 'required')">
                  This is a required field.
                </mat-error>
                <mat-error *ngIf="getPasswordValidate('password', 'minlength')"
                  >Password too short. Minimum required length: 6 characters.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <input
                  matInput
                  type="password"
                  placeholder="Confirm Password"
                  formControlName="confirmPassword"
                  required
                  [type]="confirmPassHide ? 'password' : 'text'"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="confirmPassHide = !confirmPassHide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="confirmPassHide"
                >
                  <mat-icon
                    [ngClass]="{
                      'accent-color': !confirmPassHide,
                      'disabled-color': confirmPassHide
                    }"
                    >{{
                      confirmPassHide ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </button>
                <mat-error
                  *ngIf="
                    getPasswordValidate(
                      'confirmPassword',
                      'required',
                      'password'
                    )
                  "
                >
                  This is a required field.
                </mat-error>
                <mat-error
                  *ngIf="getPasswordValidate('confirmPassword', 'minlength')"
                  >Password too short. Minimum required length: 6 characters.
                </mat-error>
                <mat-error
                  *ngIf="
                    getPasswordValidate(
                      'confirmPassword',
                      'passwordMismatch',
                      'password'
                    )
                  "
                >
                  Passwords do not match
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="mt-3">
          <mat-checkbox (change)="check($event)">
            <p class="text-responsive">
              I agree with the
              <span
                ><a
                  target="_blank"
                  href="https://loyalize.com/terms-of-service/"
                  class="link-alert"
                  >Terms of Service</a
                >
                and the
                <a
                  target="_blank"
                  href="https://loyalize.com/privacy-policy/"
                  class="link-alert"
                  >Privacy Policy</a
                ></span
              >
            </p>
          </mat-checkbox>
        </div>
      </div>
      <div class="modal-footer pb-3" mat-dialog-actions>
        <button
          [disabled]="
            (organisationId && organizationForm.invalid) || !isChecked
          "
          class="moreBrands mb-3 font-button"
          type="submit"
          (click)="activateOrganisationAndUser()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #isBroken>
  <div class="content-broken">
    <div class="close-button" (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </div>
    <div mat-dialog-content>
      <app-loader *ngIf="isLoading"></app-loader>
      <div *ngIf="!isLoading" class="mt-5 text-center">
        <div class="text-error-token">
          Your invitation link is expired. Please request a new invitation from
          <span style="text-decoration: underline"
            ><a href="https://loyalize.com/">Loyalize.com</a></span
          >
        </div>
      </div>
      <button
        *ngIf="!isLoading"
        class="moreBrands mt-5"
        (click)="dialogRef.close()"
      >
        Ok
      </button>
    </div>
  </div>
</ng-template>
