import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TextError} from '../../Layouts/Header/NewHeader/signin-modal/sign-in-modal.component';

@Component({
  selector: 'app-loader',
  templateUrl: './Loader.component.html',
  styleUrls: ['./Loader.component.scss']
})
export class LoaderComponent {
  @Input() height = 60;

}
