import {Routes} from '@angular/router';

import {AccountComponent} from './Account/Account.component';
import {Authorities} from '../../Services/data/constants';
import {AuthGuard} from '../../Services/guard';

export const UserAccountRoutes: Routes = [
  {
    path: 'loyalize-user',
    canActivate: [AuthGuard],
    data: {
      expectedRole: [Authorities.API_PARTNER],
    },
    component: AccountComponent,
    loadChildren: () => import('../../Pages/UserAccount/ApiPartner/ApiPartner.module').then(m => m.ApiPartnerModule)
  }
];
