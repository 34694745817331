import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { Observable } from "rxjs";
import { Authorities } from "../data/constants";
import { TokenService } from "../auth/token.service";
import { BroadcastService } from "../broadcast/broadcast.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private broadcastService: BroadcastService,
    private tokenService: TokenService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const permissionPath =
      state.url === "/" ||
      state.url === "/account/loyalize-user" ||
      state.url === "/account";
    const notPermissionPath = state.url.includes("/account");
    const notPermissionPathForSuportApiuUser =
      state.url.includes("/monitoring") || state.url.includes("/api-key");
    if (permissionPath && this.isAuthenticated()) {
      this.router.navigate(["/account/loyalize-user/transactions"]);
      return false;
    } else if (
      (notPermissionPath && !this.isAuthenticated()) ||
      (this.isAuthenticated() &&
        this.isAuthorities(Authorities.APIU_SUPPORT_USER) &&
        notPermissionPathForSuportApiuUser)
    ) {
      this.router.navigate(["/"]);
      return false;
    }
    return true;
  }

  isAuthenticated(): boolean {
    return !!this.tokenService.getToken();
  }

  isAuthorities(authorities: string) {
    if (
      this.isAuthenticated() &&
      this.decodeToken(this.tokenService.getToken()).authorities
    ) {
      return (
        this.decodeToken(this.tokenService.getToken()).authorities.findIndex(
          (role) => role === authorities
        ) !== -1
      );
    }
  }

  decodeToken(accessOrRefreshToken): Token {
    return jwtDecode(accessOrRefreshToken);
  }

  isAuthoritiesApiPartner(access_token?) {
    if (this.isAuthenticated()) {
      return (
        this.decodeToken(this.tokenService.getToken()).authorities.findIndex(
          (role) =>
            role === Authorities.API_PARTNER ||
            role === Authorities.APIU_SUPPORT_USER
        ) !== -1
      );
    }
    if (access_token) {
      return (
        this.decodeToken(access_token).authorities.findIndex(
          (role) =>
            role === Authorities.API_PARTNER ||
            role === Authorities.APIU_SUPPORT_USER
        ) !== -1
      );
    }
  }

  get fieldFromToken() {
    return this.decodeToken(this.tokenService.getToken()).userId;
  }

  removeToken() {
    this.tokenService.signOut();
    localStorage.removeItem("apiu_token");
  }
}

interface Token {
  exp: number;
  userId: string;
  user_name: string;
  jti: string;
  client_id: string;
  scope: string[];
  authorities: string[];
  organizationId?: string;
}
