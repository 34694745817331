import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ColumnsService} from '../../Services/column-service/column.service';


@Component({
  selector: 'app-column-customization-dialog',
  templateUrl: './column-customization-dialog.component.html',
  styleUrls: ['./column-customization-dialog.component.css']
})

export class ColumnCustomizationDialogComponent {
  searchText: string;
  constructor(
    public dialogRef: MatDialogRef<ColumnCustomizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private columnsService: ColumnsService
  ) {}

  columnVisibilityChanged(column) {
    const newDisplayedColumns = this.data.columns.filter(c => c.visible).map(c => c.name);
    this.columnsService.columnsChanged.next(newDisplayedColumns);
    localStorage.setItem(`columns-visibility-${this.data.localStorageKey}`, JSON.stringify(this.data.columns));
  }
  resetToDefault() {
    this.data.columns.forEach((column) => {
      column.visible = this.data.visibleColumns.includes(column.name);
    });
    const newDisplayedColumns = this.data.columns.filter((c) => c.visible).map((c) => c.name);
    this.columnsService.columnsChanged.next(newDisplayedColumns);
    localStorage.removeItem("columnsVisibility");
    localStorage.setItem(`columns-visibility-${this.data.localStorageKey}`,JSON.stringify(this.data.columns));
  }
  onNoClick(): void {
    this.dialogRef.close(this.data.columns);
  }

}
