import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  protected _eventSubject = new Subject();
  public events = this._eventSubject.asObservable();

  dispathEvent(event) {
    this._eventSubject.next(event);
  }
}
