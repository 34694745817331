import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface ModalData {
  isNameChange?: {
    isChangeFirstName?: boolean
    isChangeLastName?: boolean,
    isSettings?: boolean
  };
}
@Component({
  selector: 'app-ngo-join-modal',
  templateUrl: './thank-you-modal.component.html',
  styleUrls: ['./thank-you-modal.component.scss']
})
export class BankTransferModalComponent {

  constructor(
    public dialogRef: MatDialogRef<BankTransferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData) {
  }

}
