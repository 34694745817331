<!-- Top menu -->
<nav class="app-nav">
  <ul class="app-nav-list" [ngClass]="{ 'item-inactive': categoriesExpanded }">
    <li class="app-nav-item" *ngFor="let item of menuItems">
      <ng-container *ngIf="item.type == 'sub'">
        <a (click)="toggleCategories()" class="nav-link main-links">
          {{ item.name }}
          <i class="fas fa-angle-down browse-icon"></i>
        </a>
        <ul class="sub-menu" *ngIf="item.children && item.children.length > 0">
          <li class="sub-menu-link" *ngFor="let child of item.children">
            <a
              (click)="toggleSidebar()"
              class="nav-link custom-sub-menu-item"
              *ngIf="child.type == 'link'"
              [routerLink]="['/' + child.state]"
              [queryParams]="getQueryParams(child)"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: false }"
              [target]="child.target"
            >
              {{ child.name }}
            </a>
            <a
              (click)="toggleSidebar()"
              class="nav-link custom-sub-menu-item"
              *ngIf="
                child.type == 'href' &&
                child.name !== 'API Docs' &&
                child.name !== 'Loyalize FAQs'
              "
              [href]="child.state"
              [target]="child.target"
            >
              {{ child.name }}
            </a>
            <a
              (click)="toggleSidebar(); openDocumentation(event)"
              class="nav-link custom-sub-menu-item"
              *ngIf="child.type == 'href' && child.name === 'API Docs'"
              [target]="child.target"
              style="cursor: pointer"
            >
              {{ child.name }}
            </a>
            <a
              (click)="toggleSidebar()"
              class="nav-link custom-sub-menu-item"
              *ngIf="child.type == 'href' && child.name === 'Loyalize FAQs'"
              [href]="child.state"
              [target]="child.target"
              download
            >
              {{ child.name }}
            </a>
          </li>
        </ul>
      </ng-container>

      <a
        (click)="toggleSidebar()"
        class="nav-link main-links"
        *ngIf="item.type == 'link'"
        [routerLink]="['/' + item.state]"
        [queryParams]="getQueryParams(item)"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
        [target]="item.target"
      >
        {{ item.name }}
      </a>
      <a
        (click)="toggleSidebar()"
        class="nav-link main-links"
        *ngIf="item.type == 'href'"
        [href]="item.state"
        [target]="item.target"
      >
        {{ item.name }}
      </a>
    </li>
  </ul>
</nav>
