import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {interval, Subscription} from 'rxjs';
import {finalize, take, tap} from 'rxjs/operators';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {SideNavService} from '../Services/global/side-nav.service';
import {MenuItems} from '../Core/menu/menu-items/menu-items';
import {Directionality} from '@angular/cdk/bidi';
import {AuthGuard} from '../Services/guard';
import {BroadcastService} from '../Services/broadcast/broadcast.service';

declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './Main.component.html',
  styleUrls: ['./Main.component.scss']
})
export class MainComponent implements OnInit {

  timer = 0;
  isRtl: any;
  currentUrl: any;
  menuItems;
  isLoggedIn = false;
  loggedInSubscription: Subscription;

  constructor(private loader: LoadingBarService,
              public sideNavService: SideNavService,
              private broadcastService: BroadcastService,
              public authGuard: AuthGuard,
              public menu: MenuItems,
              dir: Directionality,
              private router: Router) {

    if (this.sideNavService.isDirectionRtl) {
      this.isRtl = 'rtl';
    } else {
      this.isRtl = 'ltr';
    }

    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit() {
    this.isLoggedIn = this.authGuard.isAuthoritiesApiPartner();
    this.loggedInSubscription = this.broadcastService.events.subscribe((event: any) => {
      if (event.type === 'ACCESS_TOKEN_LOGGED_IN') {
        this.isLoggedIn = event.type === 'ACCESS_TOKEN_LOGGED_IN' && this.authGuard.isAuthoritiesApiPartner();
      }
      if (event.type === 'LOGGED_OUT') {
        this.isLoggedIn = false;
      }
    });
    this.menuItems = this.menu.getMainMenu();
    this.startTimer();
  }

  public startTimer() {
    this.timer = 0;
    interval(1000).pipe(
      take(3),
      tap(value => {
        this.timer = value + 1;
      }),
      finalize(() => this.loader.complete()),
    ).subscribe();

    // We're sure that subscription has been made, we can start loading bar service
    this.loader.start();
  }

  public hideSideNav() {
    this.sideNavService.sidenavOpen = false;
  }

  /**
   * On window scroll add class responseStatus-fixed.
   */
  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollTop >= 200) {
      $('app-main').addClass('header-fixed');
    } else {
      $('app-main').removeClass('header-fixed');
    }
  }
}
