import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ToastaService, ToastOptions} from 'ngx-toasta';
import {Subscription} from 'rxjs';
import {UserService} from '../../../../Services/auth/user.service';
import {BroadcastService} from '../../../../Services/broadcast/broadcast.service';
import {AuthGuard} from '../../../../Services/guard';

@Component({
  selector: 'app-login-forgot-password',
  templateUrl: './confirm-password-modal.component.html',
  styleUrls: ['./confirm-password-modal.component.scss']
})
export class ConfirmPasswordModalComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  userId = '';
  resetPaswForm: UntypedFormGroup;
  newPassHide = true;
  confirmPassHide = true;
  isLoading = false;
  isTokenValidated: any;
  toastUpdated: ToastOptions = {
    title: 'Password Information',
    msg: 'Your changes were updated successfully!',
    showClose: true,
    timeout: 3000,
    theme: 'material'
  };


  constructor(public dialogRef: MatDialogRef<ConfirmPasswordModalComponent>,
              private router: Router,
              public userService: UserService,
              public route: ActivatedRoute,
              public toastyService: ToastaService,
              public broadcastService: BroadcastService,
              public authGuard: AuthGuard,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.formGroup();
  }

  ngOnInit(): void {
    this.redirectRouter();
    this.subscribeOnLogin();
    this.queryUser();
  }

  subscribeOnLogin() {
    this.subscription = this.broadcastService.events.subscribe((event: any) => {
      if (event.type === 'LOGGED_IN') {
        this.redirectRouter();
      }
    });
  }


  queryUser() {
    this.route.queryParams.forEach(queryParams => {
      const queryRes = queryParams[this.data];
      if (queryRes) {
        return this.userId = queryParams[this.data];
      }
    });
    if (this.userId) {
      this.isTokenValid(this.userId);
    }
  }

  isTokenValid(tokenId) {
    this.isLoading = true;
    this.userService.getTokenForgotPassword(tokenId).subscribe((res: any) => {
      this.isTokenValidated = res.isValid;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.isTokenValidated = false;
    });
  }


  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null : {passwordMismatch: true};
    };
  }


  formGroup() {
    this.resetPaswForm = new UntypedFormGroup({
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6), this.matchValues('newPassword')])
    });
  }

  redirectRouter() {
    if (this.authGuard.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }


  getPasswordValidate(parameter: string, typeOfError: string, parameter2 ?: string) {
    const formGet = this.resetPaswForm.get(parameter);
    let formGet2;
    if (parameter2) {
      formGet2 = this.resetPaswForm.get(parameter2);
    }
    if (formGet.value) {
      formGet.markAsTouched();
    }
    if (parameter2 && formGet2.value) {
      formGet.markAsTouched();
    }
    if (parameter2 && formGet2.value) {
      formGet2.markAsTouched();
    }
    return formGet.invalid && formGet.touched && formGet.errors[typeOfError];
  }

  updatePassword() {
    this.userService.updateForgotPassword(this.userId, this.resetPaswForm.value).subscribe((resp) => {
      this.dialogRef.close(this.data);
      setTimeout(() => this.toastyService.success(this.toastUpdated), 100);
      this.router.navigate(['/']).then(() => {
        this.deleteQueryParamAfterLogin();
      });
      setTimeout(() => this.broadcastService.dispathEvent({type: 'OPEN_SIGN_IN_MODAL'}), 3200);
    }, () => {
      this.toastUpdated.msg = 'Something is wrong!';
      this.toastyService.error(this.toastUpdated);
    });
    this.toastUpdated.msg = 'Your changes were updated successfully!';
  }

  deleteQueryParamAfterLogin() {
    this.route.queryParams.subscribe(c => {
      const params = Object.assign({}, c);
      delete params.uuid;
      this.router.navigate([], {relativeTo: this.route, queryParams: params});
    }).unsubscribe();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}


