import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { Menu, MenuItems } from "../../../Core/menu/menu-items/menu-items";
import { Subscription } from "rxjs";
import { AuthGuard } from "../../../Services/guard";
import { BroadcastService } from "../../../Services/broadcast/broadcast.service";
import { SideNavService } from "../../../Services/global/side-nav.service";
import { filter } from "rxjs/operators";
import { Authorities } from "../../../Services/data/constants";
import { environment } from "../../../../environments/environment";
import { UserService } from "../../../Services/auth/user.service";
import { DocsService } from "src/app/Services/docs-security/docs.service";

@Component({
  selector: "app-embryo-menu",
  templateUrl: "./Menu.component.html",
  styleUrls: ["./Menu.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() mobile: boolean;
  private categorySub: Subscription;
  menuItems: Menu[];
  categoriesExpanded = false;
  loggedInSubscription: Subscription;
  currentActiveRoute = this.router.url;
  env = environment;
  authorities = Authorities;

  constructor(
    public router: Router,
    public authGuard: AuthGuard,
    public menu: MenuItems,
    private broadcastService: BroadcastService,
    public sideNavService: SideNavService,
    public userService: UserService,
    private docs: DocsService
  ) {
    this.getActiveRoute();
  }

  ngOnInit() {
    this.getMenu();
    this.loggedInSubscription = this.broadcastService.events.subscribe(
      (event: any) => {
        if (event.type === "LOGGED_IN") {
          localStorage.removeItem("navigate");
        }
        if (event.type === "LOGGED_OUT") {
          localStorage.removeItem("rememberMe");
        }
      }
    );
  }

  selected(item) {
    const children = item.children;
    item.children = [];
    setTimeout(() => {
      item.children = children;
    }, 200);
  }

  ngOnDestroy(): void {
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
    if (this.categorySub) {
      this.categorySub.unsubscribe();
    }
  }

  getActiveRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentActiveRoute = event.url.toString();
        this.getMenu();
      });
  }

  getMenu() {
    const menuItem = this.menu.getMainMenu();
    if (localStorage.getItem("access-token")) {
      this.userService.getLoggedUserProfile().subscribe({
        next: (user: any) => {
          this.menuItems = menuItem.filter((item) => {
            if (
              this.authGuard.isAuthenticated() &&
              this.authGuard.isAuthorities(this.authorities.API_PARTNER)
            ) {
              return this.authGuard.isAuthorities(
                item.role.filter(
                  (role) => role === this.authorities.API_PARTNER
                )[0]
              );
            }
            if (
              this.authGuard.isAuthenticated() &&
              this.authGuard.isAuthorities(this.authorities.APIU_SUPPORT_USER)
            ) {
              return this.authGuard.isAuthorities(
                item.role.filter(
                  (role) => role === this.authorities.APIU_SUPPORT_USER
                )[0]
              );
            }
          });
        },
      });
    }
  }

  public toggleSidebar() {
    this.sideNavService.sidenavOpen = false;
  }

  public getQueryParams(item) {
    return item.queryState ? { categories: item.queryState } : {};
  }

  inactiveMenu() {
    setTimeout(() => {
      this.categoriesExpanded = false;
    }, 500);
    this.sideNavService.sidenavOpen = false;
  }

  openDocumentation(event) {
    this.docs.openDocumentation(event);
  }

  toggleCategories() {
    this.categoriesExpanded = !this.categoriesExpanded;
  }
}
