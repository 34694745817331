import {Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ngo-join-modal',
  templateUrl: './new-account-confirmation-modal.component.html',
  styleUrls: ['./new-account-confirmation-modal.component.scss']
})
export class NewAccountConfirmationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<NewAccountConfirmationModalComponent>) {
  }
}
