import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {MasterUserModel, UserModel, UserResetPasswModel} from '../../Models/user.model';
import {authApiUser, authLoyalizeUser, loyalizeOpenAuth} from '../data/constants';
import {IUserSetPassw} from '../../Layouts/Header/NewHeader/signin-modal/sign-in-modal.component';
import {AuthGuard} from '../guard';


@Injectable()
export class UserService {
  userProfile: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    imageUrl: '',
    phoneNumber: ''
  });
  masterProfile: BehaviorSubject<MasterUserModel> = new BehaviorSubject<MasterUserModel>({
    activationDate: '',
    email: '',
    firstName: '',
    id: null,
    lastLogin: '',
    lastName: '',
    organization: {
      activationDate: '',
      enabled: false,
      id: null,
      invitationDate: '',
      name: ''
    },
    type: ''
  });

  loggedLoyalizeUserApi = `${authLoyalizeUser}/me`;
  tokenCheckApi = `${authApiUser}/token`;
  tokenUserApi = `${authApiUser}/apiu`;

  constructor(private dataService: DataService,
              private httpClient: HttpClient,
              private authGuard: AuthGuard) {
  }


  getLoggedUserProfile(): Observable<Object> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'});
    return this.httpClient.get(this.loggedLoyalizeUserApi, {headers: headers});
  }

  updateUserProfile(user: UserModel): Observable<Object> {
    return this.httpClient.post(`${authApiUser}/${user.id}`, user);
  }

  getLoggedUserProfileUpdated(): Observable<Object> {
    const token = this.authGuard.fieldFromToken;
    return this.httpClient.get(`${authLoyalizeUser}/settings/${token}`);
  }

  updateUserProfileUpdated(user: MasterUserModel): Observable<Object> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.put(`${authLoyalizeUser}/settings/${user.id}`, user, {headers: headers});
  }

  userProfileChange(data: UserModel) {
    this.userProfile.next(data);
  }
  masterProfileChange(data: MasterUserModel) {
    this.masterProfile.next(data);
  }

  setPasswordPartner(userId: string, resetPassword: IUserSetPassw, parentId): Observable<Object> {
    let params = new HttpParams();
    if (parentId) {
      params = params.append('parentId', parentId);
    }
    return this.httpClient.post(`${authApiUser}/partners/${userId}`, resetPassword, {params: params});
  }

  forgotPassword(email): Observable<Object> {
    return this.httpClient.get(`${loyalizeOpenAuth}/${email}/forgot-password`);
  }

  updateForgotPassword(userId: string, resetPassword: UserResetPasswModel): Observable<Object> {
    return this.httpClient.post(`${loyalizeOpenAuth}/${userId}/update-forgot-password`, resetPassword);
  }


  getTokenForgotPassword(tokenId): Observable<Object> {
    return this.httpClient.get(`${loyalizeOpenAuth}/token/${tokenId}`);
  }

  getInfoWithToken(tokenId): Observable<Object> {
    return this.httpClient.get(`${this.tokenUserApi}/${tokenId}`);
  }

  getPersonalAccessToken(userId): Observable<Object> {
    return this.httpClient.get(`${authApiUser}/${userId}/personal-access-token`);
  }

  resetPersonalAccessToken(userId): Observable<Object> {
    return this.httpClient.post(`${authApiUser}/${userId}/personal-access-token/reset`, null);
  }


}
