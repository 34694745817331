import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of, timer} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPreloadStrategyService implements PreloadingStrategy {

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload ) {
      const delay: number = route.data['delay'];
      return timer(delay).pipe(
        flatMap( _ => {
          return load() ;
        }));
    } else {
      return of(null);
  }
  }

}
