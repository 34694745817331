import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {config} from './config';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {DataSnapshot} from '@angular/fire/compat/database/interfaces';
import {FirebaseApp} from '@angular/fire/compat';


@Injectable()
export class FirebaseService {

  constructor(private db: AngularFireDatabase, private firebaseApp: FirebaseApp, private angularFireAuth: AngularFireAuth) {
  }

  getHealthInfo(): Promise<DataSnapshot> {
    const env = environment.name === 'local' ? 'dev' : environment.name;
    const prevMonthTime = new Date();
    prevMonthTime.setDate(1);
    prevMonthTime.setHours(0);
    prevMonthTime.setMinutes(0);
    prevMonthTime.setSeconds(0);
    return this.firebaseApp.database()
    .ref(`/healthcheck/${env}`)
    .orderByChild('timestamp')
    .startAt(prevMonthTime.getTime())
    .once('value');
  }

  getHealthInfoLastMonth(): Promise<DataSnapshot> {
    const env = environment.name === 'local' ? 'dev' : environment.name;
    const prevMonthTimeStart = new Date();
    prevMonthTimeStart.setMonth(new Date().getMonth() - 1);
    prevMonthTimeStart.setDate(1);
    prevMonthTimeStart.setHours(0);
    prevMonthTimeStart.setMinutes(0);
    prevMonthTimeStart.setSeconds(0);

    const prevMonthTimeEnd = new Date();
    prevMonthTimeEnd.setMonth(new Date().getMonth() - 1);
    const lastDayOfMonth = new Date(prevMonthTimeEnd.getFullYear(), new Date().getMonth(), 0).getDate();
    prevMonthTimeEnd.setDate(lastDayOfMonth);
    prevMonthTimeEnd.setHours(23);
    prevMonthTimeEnd.setMinutes(59);
    prevMonthTimeEnd.setSeconds(59);

    return this.firebaseApp.database()
    .ref(`/healthcheck/${env}`)
    .orderByChild('timestamp')
    .startAt(prevMonthTimeStart.getTime())
    .endAt(prevMonthTimeEnd.getTime())
    .once('value');
  }

  authOnFirebase(): Promise<any> {
    return this.angularFireAuth
    .signInWithEmailAndPassword(config.firebaseCredentials.email, config.firebaseCredentials.password)
    .then(() => {
    }).catch(function (error) {
      // Handle error.
    });
  }

}
