import {NgModule} from '@angular/core';
import {RoundNumberPipe} from './roundNumber.pipe';
import {EscapeHtmlPipe} from './keep-html.pipe';
import {ColumnSearchPipe} from './column-search.pipe';
import {PrettyJsonPipe} from './pretty-json.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { UnderscoreToSpacePipe } from './underscore-to-space.pipe';

@NgModule({
  imports: [],
  declarations: [RoundNumberPipe, EscapeHtmlPipe, ColumnSearchPipe, PrettyJsonPipe, DateFormatPipe, UnderscoreToSpacePipe],
  exports: [RoundNumberPipe, EscapeHtmlPipe, ColumnSearchPipe, PrettyJsonPipe, DateFormatPipe, UnderscoreToSpacePipe]
})

export class PipeModule {
}
