import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ColumnsService {
  private columnsSource = new BehaviorSubject<string[]>([]);
  currentColumns = this.columnsSource.asObservable();
  columnsChanged: Subject<string[]> = new Subject<string[]>();

  constructor() {}

  changeColumns(columns: string[]) {
    this.columnsSource.next(columns);
  }

  checkNewColumns(columnDetails, page) {
    let config = JSON.parse(
      localStorage.getItem(`columns-visibility-${page}`) || "[]"
    );

    if (config) {
      columnDetails.forEach(({ label, name, visible }) => {
        const existingColumnIndex = config.findIndex(
          (item) => item.name === name
        );
        if (existingColumnIndex !== -1) {
          config[existingColumnIndex].label = label;
        } else {
          config.push({ label, name, visible });
        }
      });
      config = columnDetails.map(({ name }) =>
        config.find((item) => item.name === name)
      );

      localStorage.setItem(
        `columns-visibility-${page}`,
        JSON.stringify(config)
      );
    }
  }
}
