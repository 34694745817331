<div class="inner-container bg-white section-gap-lg not-found-page">
  <div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="80" fxFlex.lg="40" fxFlex.xl="40">
        <mat-card class="text-center pb-5">
          <h1>404</h1>
          <h5 class="mb-3">We can’t seem to find the page you’re looking for.</h5>
          <button mat-raised-button *ngIf="authGuard.isAuthoritiesApiPartner()" class="button-lg w-100 moreBrands"   [routerLink]="['/account/loyalize-user/transactions']">Go To Home</button>
        </mat-card>
      </div>
    </div>
  </div>
</div>
