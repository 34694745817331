import {Component, Input} from '@angular/core';
import {AuthGuard} from '../../Services/guard';
import {Router} from '@angular/router';
import {Authorities} from '../../Services/data/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './NewFooter.component.html',
  styleUrls: ['./NewFooter.component.scss']
})
export class NewFooterComponent {
  @Input() isLoggedIn: boolean;
  closed: boolean;
  authorities = Authorities;

  constructor(
    public authGuard: AuthGuard,
    public router: Router) {
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  close() {
    this.closed = !this.closed;
  }
}
