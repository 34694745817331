<div *ngIf="authGuard.isAuthoritiesApiPartner()" class="dropdown">
  <button mat-mini-fab class="log-in-user dropbtn">
    <img
      (mouseenter)="openMenu()"
      (click)="openMenu()"
      src="assets/images/right-sidebar/user.svg"
      class="user-icon"
      alt="log-in-user"
    />
  </button>
  <div
    class="dropdown-content hover-menu-profile user-nav-list"
    [ngClass]="{
      'dropdown-content-close': isCloseMenu,
      'dropdown-content': !isCloseMenu
    }"
  >
    <mat-list role="list" class="pb-8">
      <mat-list-item role="listitem">
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/transactions']"
          >
            <span>Transactions</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item role="listitem">
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/click-data']"
          >
            <span>Click Data</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item role="listitem">
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/sku-details']"
          >
            <span>SKU Details</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item
        *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
        role="listitem"
      >
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/payments']"
          >
            <span>Payments</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item
        *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
        role="listitem"
      >
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/bank-details']"
          >
            <span>Bank Details</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item
        *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
        role="listitem"
      >
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/webhooks']"
          >
            <span>Webhooks</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item
        *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
        role="listitem"
      >
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/api-key']"
          >
            <span>API Key</span>
          </button>
        </a>
      </mat-list-item>
      <mat-expansion-panel
        routerLinkActive="tab-active"
        [expanded]="panelOpenState"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <button style="cursor: default" mat-menu-item>
              <span>Support</span>
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item role="listitem">
          <a>
            <button
              mat-menu-item
              (click)="closeMenu()"
              [routerLink]="['/account/loyalize-user/missing-cashback']"
            >
              <span style="line-height: 1.2">Report Missing Cashback</span>
            </button>
          </a>
        </mat-list-item>
        <mat-list-item role="listitem">
          <a
            href="https://loyalize.com/docs/api/"
            target="_blank"
            (click)="openDocumentation($event)"
          >
            <button mat-menu-item (click)="closeMenu()">
              <span>API Docs</span>
            </button>
          </a>
        </mat-list-item>
        <mat-list-item role="listitem">
          <a
            download
            [href]="'/assets/docs/Loyalize-API-FAQ.pdf'"
            target="_blank"
          >
            <button mat-menu-item (click)="closeMenu()">
              <span>API FAQ</span>
            </button>
          </a>
        </mat-list-item>
        <mat-list-item role="listitem">
          <a
            href="https://loyalize.atlassian.net/servicedesk/customer/portals"
            target="_blank"
          >
            <button mat-menu-item (click)="closeMenu()">
              <span>Service Desk</span>
            </button>
          </a>
        </mat-list-item>
        <mat-list-item role="listitem">
          <a>
            <button
              mat-menu-item
              (click)="closeMenu()"
              [routerLink]="['/account/loyalize-user/monitoring']"
            >
              <span>Service Monitoring</span>
            </button>
          </a>
        </mat-list-item>
      </mat-expansion-panel>
      <mat-list-item role="listitem">
        <a>
          <button
            mat-menu-item
            (click)="closeMenu()"
            [routerLink]="['/account/loyalize-user/settings']"
          >
            <span>Settings</span>
          </button>
        </a>
      </mat-list-item>
      <mat-list-item role="listitem">
        <a>
          <button mat-menu-item (click)="onLogout(); closeMenu()">
            <span class="sign-out">Sign Out</span>
          </button>
        </a>
      </mat-list-item>
    </mat-list>
  </div>
</div>
