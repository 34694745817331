<div class="customRow" *ngIf="!isSuccess; else ifSuccess">
  <div class="content my-3">
    <div class="close-button" (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </div>
    <div mat-dialog-content>
      <h4 class="text-center">
        Enter your registered email address to receive instructions on how to
        reset the password.
      </h4>
      <app-dismiss-alert
        *ngIf="errorText"
        (deleteText)="deleteText()"
        [ngStyle]="{ textAlign: 'center' }"
        isLink="true"
        (clickOnLink)="closeModal($event)"
        [text]="errorText"
      >
      </app-dismiss-alert>
      <form [formGroup]="resetForm">
        <div class="modal-header">
          <div class="mt-3 w-100 p-0">
            <div class="list">
              <div class="mb-3">
                <mat-form-field color="#474477" class="w-100 mb-3">
                  <input
                    matInput
                    formControlName="email"
                    placeholder="Email"
                    required
                    autocomplete="false"
                  />
                  <mat-error
                    *ngIf="getEmailOrPhoneValidate('email', 'required')"
                    >This is a required field.
                  </mat-error>
                  <mat-error *ngIf="getEmailOrPhoneValidate('email', 'email')"
                    >Please enter a valid email address.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer pb-3" mat-dialog-actions>
      <button
        [disabled]="resetForm.invalid"
        class="moreBrands mb-3 font-button"
        type="submit"
        (click)="resetPassword()"
      >
        Reset your password
      </button>
      <button class="moreBrands mb-3" (click)="dialogRef.close()">
        Cancel
      </button>
    </div>
  </div>
</div>

<ng-template #ifSuccess>
  <div mat-dialog-content>
    <div class="center-content my-3">
      <h4 class="text-center m-3 font-normal">
        Check your email for confirmation!
      </h4>
      <button
        [disabled]="resetForm.invalid"
        class="moreBrands"
        type="submit"
        (click)="dialogRef.close()"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
