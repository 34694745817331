import {Injectable} from '@angular/core';
import {ToastaConfig, ToastaService} from 'ngx-toasta';

@Injectable()
export class SideNavService {

  sidenavOpen = false;
  isDirectionRtl = false;

  constructor(
    private toastyService: ToastaService,
    private toastyConfig: ToastaConfig) {

    this.toastyConfig.position = 'top-right';
    this.toastyConfig.theme = 'material';
  }
}
