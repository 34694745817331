import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../Services/auth/user.service';
import {BroadcastService} from '../../Services/broadcast/broadcast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: UntypedFormGroup;
  badCredentials = false;
  isSuccess = false;
  isLoading = false;
  errorText: TextError;

  constructor(
    public userService: UserService,
    private broadcastService: BroadcastService) {
  }

  ngOnInit() {
    this.buildForm();
  }

  public buildForm() {
    this.resetForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });

  }

  resetPassword() {
    // this.isLoading = true;
    // this.userService.forgotPassword(this.resetForm.value.email).subscribe((resp) => {
    //   this.isSuccess = true;
    //   this.errorText = null;
    //   this.badCredentials = false;
    //   this.isLoading = false;
    //
    // }, () => {
    //   this.isSuccess = false;
    //   this.errorText = {
    //     firstText: 'This email address does not exist in our system. Either re-enter a valid email address or sign up',
    //     secondText: '',
    //     linkText: ''
    //   };
    //   this.badCredentials = true;
    //   this.isLoading = false;
    //
    // });
  }

  getEmailOrPhoneValidate(parameter: string, typeOfError: string) {
    // const formGet = this.resetForm.get(parameter);
    // if (formGet.value) {
    //   formGet.markAsTouched();
    // }
    // return formGet.invalid && formGet.touched && formGet.errors[typeOfError];
  }

  deleteText() {
    this.errorText = null;
  }

  clickOnLink($event: boolean) {
  }
}

export interface TextError {
  firstText: string;
  secondText: string;
  linkText: string;

}

