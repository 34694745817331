import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class DataService {
  env = environment;
  clickDataUrl = "/v2/loyalize-dashboard/support/click-data";

  constructor(private httpClient: HttpClient) {}

  getAllTransactions(size?, page?, sort?, status?, date?): Observable<Object> {
    let params = new HttpParams();
    if (size) {
      params = params.append("size", size);
    }
    if (page) {
      params = params.append("page", page);
    }
    if (sort) {
      params = params.append("sort", sort);
    }
    if (status) {
      params = params.append("status", status);
    }
    if (date.startDate && date.endDate) {
      params = params.append("startDate", date.startDate);
      params = params.append("endDate", date.endDate);
    }

    return this.httpClient.get(`/v2/dashboard/transactions`, {
      params,
    });
  }
  getAllClickData(
    searchParams,
    date?,
    size?,
    page?,
    sort?
  ): Observable<Object> {
    let params = new HttpParams();
    if (page) {
      params = params.append("page", page);
    }

    if (size) {
      params = params.append("size", size);
    }

    if (sort) {
      params = params.append("sort", sort);
    }

    if (date.startDate && date.endDate) {
      params = params.append("clickDateStart", date.startDate);
      params = params.append("clickDateEnd", date.endDate);
    }

    if (searchParams) {
      Object.keys(searchParams).forEach((key) => {
        if (searchParams[key]) {
          params = params.append(key, searchParams[key]);
        }
      });
    }
    return this.httpClient.get(`/v2/dashboard/click-data`, {
      params,
    });
  }

  getSKUDetails(transactionId?, size?, page?): Observable<Object> {
    let params = new HttpParams();
    if (transactionId) {
      params = params.append("transactionId", transactionId);
    }
    if (size) {
      params = params.append("size", size);
    }
    if (page) {
      params = params.append("page", page);
    }
    return this.httpClient.get(`/v2/dashboard/sku-details`, { params });
  }

  getAllPayments(size?, page?, sort?, status?): Observable<Object> {
    let params = new HttpParams();
    if (size) {
      params = params.append("size", size);
    }
    if (page) {
      params = params.append("page", page);
    }
    if (sort) {
      params = params.append("sort", sort);
    }
    if (status) {
      params = params.append("payoutStatus", status);
    }
    return this.httpClient.get(`/v2/dashboard/payout-reports`, {
      params: params,
    });
  }

  getFilename(reportId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(
      `/v2/dashboard/payout-reports/${reportId}/file-report`,
      {
        responseType: "blob",
        observe: "response",
      }
    );
  }

  getShopperIds(purchaseDate: string): Observable<Object> {
    let params = new HttpParams();
    if (purchaseDate) {
      params = params.append("referenceDate", purchaseDate);
    }
    return this.httpClient.get(
      `/v2/loyalize-dashboard/support/click-data/get-shopper-ids`,
      { params }
    );
  }

  getSids(purchaseDate: string, shopperId: string): Observable<Object> {
    let params = new HttpParams();
    if (purchaseDate) {
      params = params.append("referenceDate", purchaseDate);
    }
    if (shopperId) {
      params = params.append("shopperId", shopperId);
    }
    return this.httpClient.get(`${this.clickDataUrl}/get-sids`, { params });
  }

  getStores(
    purchaseDate: string,
    shopperId: string,
    sid?: string
  ): Observable<Object> {
    let params = new HttpParams();
    if (purchaseDate) {
      params = params.append("referenceDate", purchaseDate);
    }
    if (shopperId) {
      params = params.append("shopperId", shopperId);
    }
    if (sid) {
      params = params.append("sid", sid);
    }
    return this.httpClient.get(`${this.clickDataUrl}/get-stores`, { params });
  }

  getClickData(
    purchaseDate: string,
    shopperId: string,
    storeIds: string
  ): Observable<Object> {
    let params = new HttpParams();
    if (purchaseDate) {
      params = params.append("referenceDate", purchaseDate);
    }
    if (shopperId) {
      params = params.append("shopperId", shopperId);
    }
    if (storeIds) {
      params = params.append("storeIds", storeIds);
    }
    return this.httpClient.get(`${this.clickDataUrl}`, { params });
  }
}
