<div class="customRow">
  <div class="content">
    <p
      class="text-center">Your withdrawal settings will be updated with the provided bank information!</p>
    <div class="modal-footer">
      <button class="moreBrands moreBrands-cancel mb-3" (click)="dialogRef.close()">Cancel</button>
      <button class="moreBrands mb-3" (click)="dialogRef.close(true)">Ok</button>
    </div>
  </div>
</div>
