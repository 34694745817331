<app-loader *ngIf="isLoading"></app-loader>
<div class="account-class">
  <mat-card class="example-container mat-elevation-z8">
    <div class="mb-3 title-class"><h2>Bank Details</h2></div>

  <div>
    <div class="withdraw-block">
      <div class="display-withdraw">
        <div class="align-center-info">
          <div>
            <div class="bank-icon-container"><img class="img_sp"
                      src="assets/images/right-sidebar/bank.svg" alt="">
              <p class="bank-text-icon">Bank Transfer</p></div>
          </div>
          <div *ngIf="accountNumber" style="margin-right: 20px;"
               class="margin-info-left margin-left-account">{{!isOpen ? accountNumber : ''}}</div>
        </div>
      </div>
      <div class="rowAround " style="align-items: baseline;">
        <div>
          <button class="add-button align-items" (click)="openDialogBank()"><span
            class="add-span-button">Add/Edit</span>
            <mat-icon>{{isOpen ? 'remove_circle' : 'add_circle'}}</mat-icon>
          </button>
        </div>
        <!--<button *ngIf="accountNumber" (click)="deleteBankAccount()" class="delete-button">DELETE-->
        <!--</button>-->
      </div>
    </div>

    <app-bank-transfer [isOpen]="isOpen"
                       (onSave)="onSaveExpansion($event)"
                       (onUpdateInfo)="onUpdateInfoExpansion($event)"
                       (onLoading)="onLoading($event)"
                       (onOpen)="onOpen($event)"
    ></app-bank-transfer>
  </div>
  </mat-card>
</div>

