import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthResponse} from '../../Models';
import {MasterOrganizationToken, OrganisationModel, UserRefModel, UserResetPasswModel} from '../../Models/user.model';
import {UserService} from './user.service';
import {TokenService} from './token.service';
import {AuthGuard} from '../guard';
import {BroadcastService} from '../broadcast/broadcast.service';
import {apiAuth, authLoyalizeUser, authApiUser, apiloyalizeOrganizations, advertisersServiceAdmin} from '../data/constants';
import {environment} from '../../../environments/environment';
const loginApi = `${apiAuth}/oauth/token`;
const openAuthApi = `${apiAuth}/v1/open-auth/loyalize-users`;
const organizationApiKey = `${apiloyalizeOrganizations}/loyalize-dashboard/loyalize-organizations`;

export interface IClaimReport {
  storeName: string;
  orderNumber: string;
  typeOfClaim: string;
  purchaseDate: string;
  // claimImages: string;
  shopperId: string;
  amount: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  env = environment;
  claimApi = `/v2/jira/service-request`;
  constructor(private httpClient: HttpClient,
              private userService: UserService,
              private broadcastService: BroadcastService,
              private authGuard: AuthGuard,
              private tokenService: TokenService) {
  }

  private authHeaders = new HttpHeaders({
    'Content-type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic ' + btoa('loyalize:secret')
  });

  login(email: string, password: string, agreed_tos: boolean): Promise<Object> {
    const body = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&grant_type=loyalize&client_id=loyalize`;
    return new Promise((resolve) => {
      this.httpClient.post(loginApi, body, {headers: this.authHeaders}).subscribe((authResponse: AuthResponse) => {
        if (this.authGuard.isAuthoritiesApiPartner(authResponse.access_token)) {
          this.tokenService.saveToken(authResponse.access_token);
          this.tokenService.saveRefreshToken(authResponse.refresh_token);
          const source = localStorage.getItem('source');
          if (source) { localStorage.removeItem('source'); }
          this.broadcastService.dispathEvent({type: 'LOGGED_IN'});
          resolve('ok');
        } else {
          const error = {
            error: 'invalid_grant',
            error_description: 'Bad credentials'
          };
          resolve(error);
        }
      }, ((errorResponse: HttpErrorResponse) => {
        resolve(errorResponse.error);
      }));
    });
  }

  refreshToken(refreshToken: string): Observable<any> {
    const body = `refresh_token=${refreshToken}&grant_type=refresh_token`;
    return this.httpClient.post(loginApi, body, {headers: this.authHeaders});
  }

  resendActivation(email: string) {
    return this.httpClient.post(`${authApiUser}/resend-activation?email=${encodeURIComponent(email)}`, null);
  }

  register(userRefModel: UserRefModel): Observable<Object> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post(authApiUser, userRefModel, {headers: headers});
  }

  resetPassword(userResetPasswModel: UserResetPasswModel): Observable<Object> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post(`${authLoyalizeUser}/${this.authGuard.fieldFromToken}/reset-password`, userResetPasswModel,
      {headers: headers});
  }

  postMissingReport(claimReport: IClaimReport): Observable<Object> {
    return this.httpClient.post(this.claimApi, claimReport);
  }
  postMissingReportwithClickData(claimReport: IClaimReport): Observable<Object> {
    return this.httpClient.post('/v2/jira/click-based-service-request/', claimReport);
  }

  postImageMissingReport(issueKey: string, formData): Observable<Object> {
    return this.httpClient.post(`${this.claimApi}/create-attachment/${issueKey}`, formData);
  }

  getBrands(...filtersOrSorts): Observable<Object> {
    let params = new HttpParams();
    if (filtersOrSorts && filtersOrSorts.length > 0) {
      filtersOrSorts.map((filter) => {
        const keyFilter = Object.keys(filter);
        const valueFilter = filter[keyFilter[0]];
        if (valueFilter !== null && valueFilter !== '' && valueFilter !== 0) {
          params = params.append(`${keyFilter}`, valueFilter);
        }
      });
    }
    return this.httpClient.get(`${advertisersServiceAdmin}`, {params: params});
  }

  getOrganizationToken(tokenId): Observable<Object> {
    return this.httpClient.get(`${openAuthApi}/token/${tokenId}`);
  }

  getOrganisationDetails(tokenId): Observable<Object> {
    return this.httpClient.get(`${openAuthApi}/organization-details/${tokenId}`);
  }

  activeOrganisation(organisationToken: OrganisationModel): Observable<Object> {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post(`${openAuthApi}/activate-organization`, organisationToken, {headers: headers});
  }

  getApiKeyOrganization(organizationId): Observable<Object> {
    return this.httpClient.get(`${organizationApiKey}/${organizationId}/api-key`);
  }

  resetApiKeyOrganization(organizationModel: MasterOrganizationToken, organizationId): Observable<Object> {
    return this.httpClient.post(`${organizationApiKey}/${organizationId}/api-key-reset`, organizationModel);
  }

}
