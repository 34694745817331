export const apiAuth = 'api/auth-service';
export const authApiUser = `${apiAuth}/v1/users`;
export const authLoyalizeUser = `${apiAuth}/v1/loyalize-users`;
export const loyalizeOpenAuth = `${apiAuth}/v1/open-auth/loyalize-users`;
export const apiloyalizeOrganizations = `${apiAuth}/v2`;
export const advertisersServiceAdmin = `api/advertisers-service/v1/brands/search`;

export enum Authorities {
  API_PARTNER = 'APIU',
  APIU_SUPPORT_USER = 'APIU_SUPPORT_USER',
}
