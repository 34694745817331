<div class="customRow">
  <div class="close-button"
       (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <p
      class="text-center">Account successfully created. Please log in using your credentials.</p>
    <div class="modal-footer">
      <button class="moreBrands" (click)="dialogRef.close(true)">Ok</button>
    </div>
  </div>
</div>
