import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
const TOKEN_KEY = "access-token";
const REFRESHTOKEN_KEY = "refresh-token";
const USER_KEY = "auth-user";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  constructor(private router: Router) {}

  signOut(): void {
    localStorage.clear();
    const currentUrl = window.location.href;
    if (!currentUrl.includes("spt")) {
      this.router.navigate([""]);
    }
  }

    public saveToken(token: string): void {
        localStorage.setItem(TOKEN_KEY, token);
        const user = this.getUser();
        if (user.id) {
            this.saveUser({...user, accessToken: token});
        }
    }

    public saveRefreshToken(token: string): void {
        localStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    public getToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    public getRefreshToken(): string | null {
        return localStorage.getItem(REFRESHTOKEN_KEY);
    }

    public saveUser(user: any): void {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    public getUser(): any {
        const user = localStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }

        return {};
    }
}
