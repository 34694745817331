import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TextError} from '../../Layouts/Header/NewHeader/signin-modal/sign-in-modal.component';

@Component({
  selector: 'app-dismiss-alert',
  templateUrl: './Alerts.component.html',
  styleUrls: ['./Alerts.component.scss']
})
export class AlertsComponent {
  @Input() text: TextError;
  @ViewChild('alert', {static: true}) alert: ElementRef;
  @Output() deleteText: EventEmitter<any> = new EventEmitter();
  @Output() clickOnLink: EventEmitter<boolean> = new EventEmitter();

  closeAlert() {
    this.alert.nativeElement.style.display = 'none';
    this.deleteText.emit();
  }

  action() {
    this.clickOnLink.emit(true);
  }
}
