import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WithdrawComponent} from './withdraw.component';
import {WithdrawService} from '../../../Services/data/withdraw.service';
import {HeaderUserProfileDropdownComponent} from '../../../GlobalEmbryo/HeaderUserProfileDropdown/HeaderUserProfileDropdown.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import { BankTransferExpansionComponent } from './bannk-transfer/bank-transfer-expansion.component';
import {NumberOnlyModule} from '../../../Services/form/number-only.module';
import {BankTransferModalComponent} from './bank-transfer-modal/bank-transfer-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BankConfirmationModalComponent} from './bank-confirmation-modal/bank-confirmation-modal.component';
import {LoaderModule} from '../../../Components/loader-screen/Loader.module';

@NgModule({
  declarations: [WithdrawComponent, BankTransferExpansionComponent,  BankTransferModalComponent, BankConfirmationModalComponent],
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatTooltipModule,
    MatDividerModule,
    NumberOnlyModule,
    MatAutocompleteModule,
    LoaderModule,
    MatExpansionModule],
  providers: [
    WithdrawService, HeaderUserProfileDropdownComponent
  ],
  exports: [
    WithdrawComponent,
  ]

})
export class WithdrawModule {
}
