<header>
  <div class="header-container" *ngIf="!isLoggedIn">
    <div class="toolbar" role="banner">
      <div class="logo-menu-class">
        <div
          class="custom-container"
          (click)="redirectAfterEmailVerification()"
        >
          <img
            class="logo display-img"
            alt="Logo"
            src="../../../../assets/loyalize-logo.svg"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="header-container header-logged-in" *ngIf="isLoggedIn">
    <div class="d-flex">
      <nav class="navMobile hidePC">
        <button
          class="responsive-toggle toggleButton"
          mat-icon-button
          (click)="toggleSidebar()"
        >
          <i class="material-icons toggleIcon">menu</i>
        </button>
      </nav>
      <div
        class="hidePC custom-container"
        (click)="redirectAfterEmailVerification()"
      >
        <img
          class="logo logo-logged-in display-img"
          alt="Logo"
          src="../../../../assets/loyalize-logo.svg"
        />
      </div>
    </div>
    <nav class="navigation"></nav>
  </div>
</header>
