import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderUserProfileDropdownComponent} from './HeaderUserProfileDropdown/HeaderUserProfileDropdown.component';
import {FormService} from '../Services/form/form';
import {PipeModule} from '../Services/pipe/pipe.module';
import {SignInModalComponent} from '../Layouts/Header/NewHeader/signin-modal/sign-in-modal.component';
import {AlertsComponent} from '../Components/Alerts/Alerts.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CheckBoxRememberMeComponent} from '../Layouts/Header/NewHeader/CheckBoxRememberMe/CheckBoxRememberMe.component';
import {ForgotPasswordModalComponent} from '../Layouts/Header/NewHeader/forgot-password-modal/forgot-password-modal.component';
import {ConfirmPasswordModalComponent} from '../Layouts/Header/NewHeader/confirm-password-modal/confirm-password-modal.component';
import {LoaderModule} from '../Components/loader-screen/Loader.module';
import {ModalCreatePasswordComponent} from '../Layouts/Header/NewHeader/modal-create-password/modal-create-password.component';
import {NewAccountConfirmationModalComponent} from '../Layouts/Header/NewHeader/new-account-confirmation-modal/new-account-confirmation-modal.component';
import {ColumnCustomizationDialogComponent} from '../Components/column-customization-dialog/column-customization-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    PipeModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
  ],
  declarations: [
    SignInModalComponent,
    AlertsComponent,
    CheckBoxRememberMeComponent,
    ForgotPasswordModalComponent,
    ConfirmPasswordModalComponent,
    NewAccountConfirmationModalComponent,
    ModalCreatePasswordComponent,
    ColumnCustomizationDialogComponent
  ],
  exports: [
    MatAutocompleteModule,
    AlertsComponent,
    ColumnCustomizationDialogComponent
  ],
  providers: [FormService],
})
export class GlobalModule {
}
