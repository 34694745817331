import { Component, OnInit } from '@angular/core';
import {AuthGuard} from '../../Services/guard';
import {Authorities} from '../../Services/data/constants';

@Component({
  selector: 'app-embryo-not-found',
  templateUrl: './NotFound.component.html',
  styleUrls: ['./NotFound.component.scss']
})
export class NotFoundComponent implements OnInit {
  authorities = Authorities;
  constructor(public authGuard: AuthGuard) { }

  ngOnInit() {
  }

}
