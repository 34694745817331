import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {WithdrawService} from '../../../Services/data/withdraw.service';
import {UserService} from '../../../Services/auth/user.service';
import {MatDialog} from '@angular/material/dialog';
import {UserModel} from '../../../Models/user.model';
import {BroadcastService} from '../../../Services/broadcast/broadcast.service';
import {BankConfirmationModalComponent} from './bank-confirmation-modal/bank-confirmation-modal.component';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {
  isLoading = false;
  isOpen = false;
  accountNumber = '';


  constructor(private withdrawService: WithdrawService,
              public dialog: MatDialog,
              private broadcastService: BroadcastService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.isLoading = true;
  }

  openDialogBank(): void {
    this.isOpen = !this.isOpen;
  }

  openDialogChangeBankFields(isNameChange, userProfile) {
    const dialogRef = this.dialog.open(BankConfirmationModalComponent, {
      width: '600px',
      minHeight: '138px',
      panelClass: 'custom-modalbox',
      disableClose: true,
      data: {isNameChange}
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.dispatchUpdateUser(userProfile);
      } else {
        this.broadcastService.dispathEvent({type: 'USER_BY_DEFAULT'});
      }
    });
  }

  updateUserProfile(userProfile) {
    this.isLoading = true;
    this.userService.updateUserProfile(userProfile).subscribe((response: UserModel) => {
      this.userService.userProfileChange(response);
      this.isLoading = false;
    }, (errorResponse: HttpErrorResponse) => {
      this.isLoading = false;
    });
  }

  deleteBankAccount() {
    this.isLoading = true;
    this.withdrawService.deleteWithdrawBank().subscribe(() => {
      this.accountNumber = '';
      this.broadcastService.dispathEvent({type: 'DELETE_BANK_DETAILS'});
      this.isOpen = false;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  onUpdateInfoExpansion(event: string) {
    this.accountNumber = event;
  }

  onSaveExpansion(event: SaveUserSetting) {
    this.openDialogChangeBankFields(event.isNotChange, event.user);
  }

  dispatchUpdateUser(userProfile) {
    this.broadcastService.dispathEvent({type: 'UPDATE_USER'});
    // return this.updateUserProfile(userProfile);
  }

  onLoading(event: boolean) {
    this.isLoading = event;
  }

  onOpen(event: boolean) {
    this.isOpen = event;
  }
}

interface SaveUserSetting {
  user: any;
  isNotChange: boolean;
}
