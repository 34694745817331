import { Injectable } from "@angular/core";
import { AuthGuard } from "../../../Services/guard";
import { Authorities } from "../../../Services/data/constants";

/*
 * Menu interface
 */
export interface Menu {
  state: string;
  name?: string;
  npo?: boolean;
  shopper?: boolean;
  type?: string;
  icon?: string;
  isLogged?: boolean;
  queryState?: number;
  children?: Menu[];
  leftMenu?: any[];
  rightMenu?: any[];
  role?: any[];
  target?: string;
}

const LeftMenuItems = [
  {
    state: "/account/loyalize-user/transactions",
    name: "Transactions",
    type: "link",
    icon: "party_mode",
    role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
  },
  {
    state: "/account/loyalize-user/click-data",
    name: "Click Data",
    type: "link",
    icon: "party_mode",
    role: [Authorities.API_PARTNER],
  },
  {
    state: "/account/loyalize-user/sku-details",
    name: "SKU Details",
    type: "link",
    icon: "party_mode",
    role: [Authorities.API_PARTNER],
  },
  {
    state: "/account/loyalize-user/payments",
    name: "Payments",
    type: "link",
    icon: "party_mode",
    role: [Authorities.API_PARTNER],
  },
  {
    state: "/account/loyalize-user/bank-details",
    name: "Bank Details",
    type: "link",
    icon: "party_mode",
    role: [Authorities.API_PARTNER],
  },
  {
    state: "/account/loyalize-user/webhooks",
    name: "Webhooks",
    type: "link",
    icon: "party_mode",
    role: [Authorities.API_PARTNER],
  },
  {
    state: "/account/loyalize-user/api-key",
    name: "API Key",
    type: "link",
    icon: "party_mode",
    role: [Authorities.API_PARTNER],
  },
  {
    state: "",
    name: "Support",
    type: "sub",
    icon: "",
    role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
    children: [
      {
        state: "/account/loyalize-user/missing-cashback",
        name: "Report Missing Cashback",
        type: "link",
        icon: "",
        role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
      },
      {
        state: "https://loyalize.com/docs/api-secured/",
        name: "API Docs",
        type: "href",
        icon: "party_mode",
        target: "_blank",
        role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
      },
      {
        state: "/assets/docs/Loyalize-API-FAQ.pdf",
        name: "API FAQ",
        type: "href",
        icon: "party_mode",
        target: "_blank",
        role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
      },
      {
        state: "https://loyalize.atlassian.net/servicedesk/customer/portals",
        name: "Service Desk",
        type: "href",
        icon: "party_mode",
        target: "_blank",
        role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
      },
      {
        state: "/account/loyalize-user/monitoring",
        name: "Service Monitoring",
        type: "link",
        icon: "party_mode",
        role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
      },
    ],
  },
  {
    state: "/account/loyalize-user/settings",
    name: "Settings",
    type: "link",
    icon: "party_mode",
    role: [Authorities.APIU_SUPPORT_USER, Authorities.API_PARTNER],
  },
];

@Injectable()
export class MenuItems {
  constructor(public authGuard: AuthGuard) {}

  getMainMenu(): Menu[] {
    return LeftMenuItems;
  }
}
