<div class="customRow">
  <div class="content">
    <div *ngIf="isTokenValidated; else isBroken">
      <div mat-dialog-content>
        <h4 class="text-center">Enter your password bellow</h4>
        <form [formGroup]="resetPaswForm">
          <div class="mt-3 p-0">
            <div class="list">
              <mat-form-field class="w-100 mb-4">
                <input
                  [type]="newPassHide ? 'password' : 'text'"
                  matInput
                  type="email"
                  placeholder="Enter New Password"
                  formControlName="newPassword"
                  autocomplete="new-password"
                  required
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="newPassHide = !newPassHide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="newPassHide"
                >
                  <mat-icon
                    [ngClass]="{
                      '#474477': !newPassHide,
                      'disabled-color': newPassHide
                    }"
                    >{{
                      newPassHide ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </button>
                <mat-error
                  *ngIf="getPasswordValidate('newPassword', 'required')"
                >
                  This is a required field.
                </mat-error>
                <mat-error
                  *ngIf="getPasswordValidate('newPassword', 'minlength')"
                  >Password too short. Minimum required length: 6 characters.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100 mb-4">
                <input
                  [type]="confirmPassHide ? 'password' : 'text'"
                  matInput
                  placeholder="Retype Password"
                  autocomplete="new-password"
                  formControlName="confirmNewPassword"
                  required
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="confirmPassHide = !confirmPassHide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="confirmPassHide"
                >
                  <mat-icon
                    [ngClass]="{
                      'accent-color': !confirmPassHide,
                      'disabled-color': confirmPassHide
                    }"
                    >{{
                      confirmPassHide ? "visibility_off" : "visibility"
                    }}</mat-icon
                  >
                </button>
                <mat-error
                  *ngIf="
                    getPasswordValidate(
                      'confirmNewPassword',
                      'required',
                      'newPassword'
                    )
                  "
                >
                  This is a required field.
                </mat-error>
                <mat-error
                  *ngIf="getPasswordValidate('confirmNewPassword', 'minlength')"
                  >Password too short. Minimum required length: 6 characters.
                </mat-error>
                <mat-error
                  *ngIf="
                    getPasswordValidate(
                      'confirmNewPassword',
                      'passwordMismatch',
                      'newPassword'
                    )
                  "
                >
                  Passwords do not match
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer pb-3" mat-dialog-actions>
        <button
          [disabled]="userId && resetPaswForm.invalid"
          class="moreBrands mb-3 font-button"
          type="submit"
          (click)="updatePassword()"
        >
          Save
        </button>
        <button class="moreBrands mb-3" (click)="dialogRef.close()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #isBroken>
  <div mat-dialog-content>
    <app-loader *ngIf="isLoading"></app-loader>
    <div *ngIf="!isLoading" class="mt-5 text-center">
      <h3 class="font-bold">The link has expired.</h3>
    </div>
    <button
      *ngIf="!isLoading"
      class="moreBrands mt-5"
      (click)="dialogRef.close()"
    >
      Ok
    </button>
  </div>
</ng-template>
