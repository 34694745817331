import {Routes} from '@angular/router';
import {MainComponent} from './Main/Main.component';
import {NotFoundComponent} from './Pages/NotFound/NotFound.component';
import {AuthGuard} from './Services/guard';
import {SignInModalComponent} from './Layouts/Header/NewHeader/signin-modal/sign-in-modal.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: SignInModalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'not-found',
        component: NotFoundComponent
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        data: {
          preload: true,
          delay: 5000
        },
        loadChildren: () => import('./Pages/UserAccount/UserAccount.module').then(m => m.UserAccountModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];
