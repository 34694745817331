<div class="customRow">
  <div class="content">
    <p
      *ngIf="!data.isNameChange.isChangeFirstName && data.isNameChange.isChangeLastName || !data.isNameChange.isChangeLastName && data.isNameChange.isChangeFirstName"
      class="text-center text-responsive " style=" margin-top: 20px;">
      Your profile settings will be updated with the newly
      entered {{!data.isNameChange.isChangeFirstName ? 'First' : 'Last'}} Name. </p>
    <p *ngIf="!data.isNameChange.isChangeFirstName && !data.isNameChange.isChangeLastName"
       class="text-center text-responsive " style=" margin-top: 20px;">
      Your profile settings will be updated with the newly entered First Name and Last Name.</p>
    <p
      *ngIf="data.isNameChange.isChangeFirstName && data.isNameChange.isChangeLastName && data.isNameChange.isSettings"
      class="text-center">Your profile settings will be updated with the newly entered changes.</p>
    <p
      *ngIf="data.isNameChange.isChangeFirstName && data.isNameChange.isChangeLastName && !data.isNameChange.isSettings"
      class="text-center">Your withdrawal settings will be updated with the provided bank
      information!</p>
    <div class="modal-footer">
      <button class="moreBrands moreBrands-cancel mb-3" (click)="dialogRef.close()">Cancel</button>
      <button class="moreBrands mb-3" (click)="dialogRef.close(true)">Ok</button>
    </div>
  </div>
</div>
