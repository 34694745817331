import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {MasterOrganizationModel, OrganisationModel} from '../../../../Models/user.model';
import {AuthService} from '../../../../Services/auth';
import {ToastaService, ToastOptions} from 'ngx-toasta';
import {HttpErrorResponse} from '@angular/common/http';
import {NewAccountConfirmationModalComponent} from '../new-account-confirmation-modal/new-account-confirmation-modal.component';

@Component({
  selector: 'app-modal-create-password',
  templateUrl: './modal-create-password.component.html',
  styleUrls: ['./modal-create-password.component.scss']
})
export class ModalCreatePasswordComponent implements OnInit {
  isLoading = false;
  isChecked;
  toastUpdated: ToastOptions = {
    title: 'Password Information',
    msg: 'Your changes were updated successfully!',
    showClose: true,
    timeout: 5000,
    theme: 'material'
  };
  organisationId = '';
  organizationForm: UntypedFormGroup;
  isTokenValidated: any;
  newPassHide = true;
  confirmPassHide = true;

  constructor(public dialogRef: MatDialogRef<ModalCreatePasswordComponent>,
              private authService: AuthService,
              private route: ActivatedRoute,
              private toastyService: ToastaService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit(): void {
    this.queryUser();
  }


  queryUser() {
    this.route.queryParams.forEach(queryParams => {
      const queryRes = queryParams[this.data];
      if (queryRes) {
        return this.organisationId = queryParams[this.data];
      }
    });

    if (this.organisationId) {
      this.tokenValid(this.organisationId);
    }
  }

  check({checked}) {
    this.isChecked = checked;
  }

  tokenValid(tokenId) {
    this.isLoading = true;
    this.authService.getOrganizationToken(tokenId).subscribe((user: any) => {
      this.getOrganizationInfo();
    }, () => {
      this.isLoading = false;
      this.isTokenValidated = false;
    });
  }

  getOrganizationInfo() {
    this.authService.getOrganisationDetails(this.organisationId).subscribe((organisation: MasterOrganizationModel) => {
      const organisationProfile = organisation;
      this.formGroup(organisationProfile);
      this.isTokenValidated = true;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }


  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null : {passwordMismatch: true};
    };
  }

  formGroup(organisationProfile) {
    const {firstName, lastName, organizationName, email} = organisationProfile;
    this.organizationForm = new UntypedFormGroup({
      token: new UntypedFormControl(this.organisationId),
      firstName: new UntypedFormControl(firstName, Validators.required),
      lastName: new UntypedFormControl(lastName, Validators.required),
      email: new UntypedFormControl(email, Validators.email),
      organizationName: new UntypedFormControl(organizationName, Validators.required),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6), this.matchValues('password')]),
    });
  }

  getPasswordValidate(parameter: string, typeOfError: string, parameter2 ?: string) {
    const formGet = this.organizationForm.get(parameter);
    let formGet2;
    if (parameter2) {
      formGet2 = this.organizationForm.get(parameter2);
    }
    if (formGet.value) {
      formGet.markAsTouched();
    }
    if (parameter2 && formGet2.value) {
      formGet.markAsTouched();
    }
    if (parameter2 && formGet2.value) {
      formGet2.markAsTouched();
    }
    return formGet.invalid && formGet.touched && formGet.errors[typeOfError];
  }

  activateOrganisationAndUser() {
    this.isLoading = true;
    this.authService.activeOrganisation(this.organizationForm.value).subscribe((res: OrganisationModel) => {
      this.dialogRef.close();
      this.openConfirmationNewAcountDialog();
    }, (errorResponse: HttpErrorResponse) => {
      this.toastUpdated.msg = 'Something is wrong!';
      this.toastyService.error(this.toastUpdated);
    });
    this.toastUpdated.msg = 'Your changes were updated successfully!';
  }

  openConfirmationNewAcountDialog() {
    const dialogRef = this.dialog.open(NewAccountConfirmationModalComponent, {
      width: '700px',
      minHeight: '200px',
      panelClass: 'custom-modalbox'
    });
    dialogRef.afterClosed().subscribe(data => {

    });
  }
}


