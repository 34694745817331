import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ForgotPasswordModalComponent} from "../forgot-password-modal/forgot-password-modal.component";

@Component({
  selector: 'app-checkbox-remember',
  templateUrl: './CheckBoxRememberMe.component.html',
  styleUrls: ['./CheckBoxRememberMe.component.scss']
})
export class CheckBoxRememberMeComponent {
  checked: boolean;
  @Output() toCloseModal: EventEmitter<boolean> = new EventEmitter();
  @Output() isCheckedTos: EventEmitter<boolean> = new EventEmitter();

  constructor(public dialog: MatDialog) {
  }

  check() {
    this.checked = !this.checked;
    return this.checked ? localStorage.setItem('rememberMe', 'true') : localStorage.removeItem('rememberMe');
  }

  confirmationModal() {
    const dialogRef = this.dialog.open(ForgotPasswordModalComponent, {
      width: '600px',
      minHeight: '138px',
      panelClass: 'custom-modalbox',
    });
    dialogRef.afterClosed().subscribe((resp) => {

    });
  }


}
