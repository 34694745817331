import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import { AuthGuard } from "../../Services/guard";
import { BroadcastService } from "../../Services/broadcast/broadcast.service";
import { environment } from "../../../environments/environment";
import { Authorities } from "../../Services/data/constants";
import { DocsService } from "src/app/Services/docs-security/docs.service";

@Component({
  selector: "app-header-user-profile-dropdown",
  templateUrl: "./HeaderUserProfileDropdown.component.html",
  styleUrls: ["./HeaderUserProfileDropdown.component.scss"],
})
export class HeaderUserProfileDropdownComponent {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  isCloseMenu = false;
  env = environment;
  authorities = Authorities;
  panelOpenState = false;

  constructor(
    public router: Router,
    public authGuard: AuthGuard,
    private broadcastService: BroadcastService,
    private docs: DocsService
  ) {}

  onLogout() {
    this.broadcastService.dispathEvent({ type: "LOGGED_OUT" });
    localStorage.removeItem("organizationName");
    this.authGuard.removeToken();
    this.router.navigate(["/"]);
  }

  closeMenu() {
    this.isCloseMenu = true;
  }

  openMenu() {
    this.isCloseMenu = false;
  }

  openDocumentation(event) {
    this.docs.openDocumentation(event);
  }
}
