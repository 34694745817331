import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../Services/auth/user.service';
import {TextError} from '../signin-modal/sign-in-modal.component';
import {BroadcastService} from '../../../../Services/broadcast/broadcast.service';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  resetForm: UntypedFormGroup;
  isSuccess = false;
  badCredentials = false;
  errorText: TextError;


  constructor(public dialogRef: MatDialogRef<ForgotPasswordModalComponent>,
              public userService: UserService,
              public broadcastService: BroadcastService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  public buildForm() {
    this.resetForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  resetPassword() {
    this.userService.forgotPassword(this.resetForm.value.email).subscribe((data) => {
      this.isSuccess = true;
      this.errorText = null;
      this.badCredentials = false;
    }, () => {
      this.isSuccess = false;
      this.errorText = {
        firstText: 'This email address does not exist in our system. Either re-enter a valid email address or sign up',
        secondText: '',
        linkText: ''
      };
      this.badCredentials = true;
    });
  }

  getEmailOrPhoneValidate(parameter: string, typeOfError: string) {
    const formGet = this.resetForm.get(parameter);
    if (formGet.value) {
      formGet.markAsTouched();
    }
    return formGet.invalid && formGet.touched && formGet.errors[typeOfError];
  }

  closeModal(event) {
    if (event) {
      this.dialogRef.close();
      this.broadcastService.dispathEvent({type: 'OPEN_SIGN_UP_MODAL'});
    }
  }

  deleteText() {
    this.errorText = null;
  }


}
