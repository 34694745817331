<mat-card class="example-container mat-elevation-z8 mat-container">
  <div
    class="custom-container"
    [ngClass]="{ 'custom-container-fluid': isLoggedIn }"
  >
    <div *ngIf="isLoggedIn" class="bottom-menu"></div>
    <div class="top-menu">
      <div class="footer-logo">
        © {{ getCurrentYear() }} Loyalize
      </div>
    </div>
  </div>
</mat-card>
