import {Component, OnInit} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {NavigationStart, Router} from '@angular/router';
import {googleAnalytics} from '../assets/scripts/ga/ga-script';
import {TitleService} from './Services/title/title.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private loadingBar: LoadingBarService,
    private titleService: TitleService,
    private router: Router) {
    this.titleService.setTitle();

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      const url = event['url'];
      if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
        googleAnalytics(url);
      }
    });
  }

  ngOnInit() {
    setTimeout(() => this.stopLoading(), 1000);
  }

  stopLoading() {
    this.loadingBar.complete();
  }
}
