import {NavigationEnd, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class TitleService {
  constructor(private titleService: Title,
              private meta: Meta,
              private router: Router) {

  }

  setTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.router))
      .subscribe((event) => {
          const state = this.router.routerState;
          const parent = this.router.routerState.root;
          const defaultText = 'Loyalize.com Dashboard';
          const title = this.getTag(state, parent, 'title').join(' | ');
          this.titleService.setTitle(this.getText(title, defaultText));
        }
      );
  }

  getTag(state, parent, tag: string) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data[tag]) {
      data.push(parent.snapshot.data[tag]);
    }

    if (state && parent) {
      data.push(...this.getTag(state, state.firstChild(parent), tag));
    }
    return data;
  }

  getText(text, defoultText): string {
    return text ? text : defoultText;
  }
}
