import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'columnSearch'})
export class ColumnSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(it => it.label.toLowerCase().includes(searchText));
  }
}
