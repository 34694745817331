import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BankTransferModel} from '../../Pages/UserAccount/withdraw/bannk-transfer/bank-transfer-expansion.component';
import {environment} from '../../../environments/environment';

@Injectable()
export class WithdrawService {
  env = environment;
  urlBankOptions = 'api/auth-service/v2/loyalize-dashboard/bank-details';
  constructor(private httpClient: HttpClient) {
  }

  getWithdrawBank(): Observable<Object> {
    return this.httpClient.get(this.urlBankOptions);
  }

  setWithdrawBank(bankAccountObject: BankTransferModel): Observable<Object> {
    return this.httpClient.post(this.urlBankOptions, bankAccountObject);
  }

  deleteWithdrawBank(): Observable<Object> {
    return this.httpClient.delete(this.urlBankOptions);
  }
}
