<mat-accordion class="example-headers-align">
  <mat-expansion-panel
    [expanded]="isOpen"
    (closed)="isClosedExpansion()"
    (opened)="isClosedExpansion()"
    hideToggle
  >
    <form
      *ngIf="bankTransferForm"
      [formGroup]="bankTransferForm"
      class="form-bank"
    >
      <mat-card class="my-2 p-0 box-shadow-none">
        <div class="form-container">
          <div>
            <div *ngIf="errorMessage" class="alert" role="alert">
              <strong></strong> {{ errorMessage }}
            </div>
            <mat-form-field
              color="#474477"
              class="w-100 field-class"
              appearance="outline"
            >
              <mat-label>Company Name</mat-label>
              <input
                matInput
                type="text"
                required
                placeholder="Company Name *"
                formControlName="name"
                (input)="inputChange('organizationNameInput', 'name')"
              />
              <mat-error *ngIf="getEmailOrPhoneValidate('name', 'required')">
                This field is required.
              </mat-error>
              <mat-hint>{{ textUnderInput }}</mat-hint>
            </mat-form-field>
          </div>
          <mat-form-field
            color="#474477"
            class="w-100 field-class"
            appearance="outline"
          >
            <mat-label>Routing Number</mat-label>
            <input
              matInput
              type="text"
              numbersOnly
              required
              placeholder="Routing Number *"
              formControlName="routingNumber"
              (input)="inputChange('routingNumberInput', 'routingNumber')"
            />
            <button
              mat-button
              type="button"
              *ngIf="routingNumberInput.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="routingNumberInput.setValue('')"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="getEmailOrPhoneValidate('routingNumber', 'required')"
            >
              This field is required.
            </mat-error>
            <mat-error
              *ngIf="getEmailOrPhoneValidate('routingNumber', 'minlength')"
            >
              Routing number always consists of
              {{ routingNumberMinOrMaxLength }} digits.
            </mat-error>
            <mat-error
              *ngIf="getEmailOrPhoneValidate('routingNumber', 'maxlength')"
            >
              Routing Number too long. Maximum required length:
              {{ routingNumberMinOrMaxLength }}
              characters.
            </mat-error>
          </mat-form-field>
          <mat-form-field
            color="#474477"
            class="w-100 field-class"
            appearance="outline"
          >
            <mat-label>Account Number</mat-label>
            <input
              matInput
              type="text"
              numbersOnly
              required
              placeholder="Account Number *"
              formControlName="accountNumber"
              (input)="inputChange('accountNumberInput', 'accountNumber')"
            />
            <button
              mat-button
              type="button"
              *ngIf="accountNumberInput.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="accountNumberInput.setValue('')"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="getEmailOrPhoneValidate('accountNumber', 'required')"
            >
              This field is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field
            color="#474477"
            class="w-100 field-class"
            appearance="outline"
          >
            <mat-label>Confirm Account Number</mat-label>

            <input
              class="text-responsive"
              formControlName="accountNumberConfirm"
              type="text"
              numbersOnly
              required
              [errorStateMatcher]="matcher"
              matInput
              (input)="
                inputChange('accountNumberConfirmInput', 'accountNumberConfirm')
              "
              placeholder="Confirm Account Number *"
            />
            <button
              mat-button
              type="button"
              *ngIf="accountNumberConfirmInput.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="accountNumberConfirmInput.setValue('')"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                accountNumberConfirmInput.value &&
                bankTransferForm.hasError('accountMismatch')
              "
            >
              Account Number do not match
            </mat-error>
            <mat-error
              *ngIf="
                getEmailOrPhoneValidate('accountNumberConfirm', 'required')
              "
            >
              This field is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field color="#474477" class="w-100" appearance="outline">
            <mat-label>Select Account Type</mat-label>
            <select formControlName="accountType" matNativeControl required>
              <ng-template
                ngFor
                let-item
                [ngForOf]="selectAccountItems"
                let-i="index"
                [ngForTrackBy]="trackByFn"
              >
                <option [value]="item.value">{{ item.field }}</option>
              </ng-template>
            </select>
            <mat-error
              *ngIf="getEmailOrPhoneValidate('accountType', 'required')"
            >
              This field is required.
            </mat-error>
          </mat-form-field>
          <p class="address-class">Recipient's Legal Address</p> 
          <mat-hint>{{ textForAddress }}</mat-hint>
          <div formGroupName="address">
            <mat-form-field color="#474477" class="w-100" appearance="outline">
              <mat-label>Country</mat-label>
              <input
                matInput
                type="text"
                required
                disabled
                readonly
                formControlName="country"
              />
              <mat-error
                *ngIf="getEmailOrPhoneValidate('address.country', 'required')"
              >
                This field is required.
              </mat-error>
            </mat-form-field>
            <mat-form-field
              color="#474477"
              class="w-100 field-class"
              appearance="outline"
            >
              <mat-label>Address Line 1</mat-label>
              <input matInput type="text" required formControlName="address1" />
              <button
                mat-button
                type="button"
                *ngIf="address1Input.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="address1Input.setValue('')"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf="getEmailOrPhoneValidate('address.address1', 'required')"
              >
                This field is required.
              </mat-error>
            </mat-form-field>
            <mat-form-field
              color="#474477"
              class="w-100 field-class"
              appearance="outline"
            >
              <mat-label>Address Line 2</mat-label>
              <input matInput type="text" formControlName="address2" />
              <button
                mat-button
                type="button"
                *ngIf="address2Input.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="address2Input.setValue('')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field
              color="#474477"
              class="w-100 field-class"
              appearance="outline"
            >
              <mat-label>City</mat-label>
              <input
                type="text"
                matInput
                required
                (ngModelChange)="inputCityChange($event)"
                formControlName="city"
                [matAutocomplete]="autoGroup"
              />
              <button
                mat-button
                type="button"
                *ngIf="cityInput.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="cityInput.setValue('')"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf="getEmailOrPhoneValidate('address.city', 'required')"
              >
                This field is required.
              </mat-error>
              <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup
                  *ngFor="
                    let group of stateGroupOptions | async;
                    trackBy: trackByFn
                  "
                  [label]="group.state"
                >
                  <mat-option
                    *ngFor="let name of group.city; trackBy: trackByFn"
                    [value]="name"
                    (click)="getState(group.state)"
                  >
                    {{ name }}
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
              color="#474477"
              class="w-100 field-class"
              appearance="outline"
            >
              <mat-label>State</mat-label>
              <input
                matInput
                required
                formControlName="region"
                [matAutocomplete]="autoGroupState"
                [value]="state"
              />
              <button
                mat-button
                type="button"
                *ngIf="regionInput.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="getState('')"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf="getEmailOrPhoneValidate('address.region', 'required')"
              >
                This field is required.
              </mat-error>
              <mat-autocomplete #autoGroupState="matAutocomplete">
                <mat-optgroup
                  *ngFor="
                    let stateFind of filteredStates | async;
                    trackBy: trackByFn
                  "
                  [label]="stateFind.abbreviation"
                >
                  <mat-option
                    [value]="stateFind.name"
                    (click)="getAbbreviation(stateFind.name)"
                  >
                    <span>{{ stateFind.name }}</span>
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
              color="#474477"
              class="w-100 field-class"
              appearance="outline"
            >
              <mat-label>Postal/ZIP Code</mat-label>
              <input
                matInput
                type="text"
                required
                numbersOnly
                formControlName="postalCode"
              />
              <button
                mat-button
                type="button"
                *ngIf="postalCodeInput.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="postalCodeInput.setValue('')"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  getEmailOrPhoneValidate('address.postalCode', 'required')
                "
              >
                This field is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>

    <mat-action-row>
      <button
        type="submit"
        (click)="submitModal()"
        [disabled]="!this.bankTransferForm?.valid"
        class="moreBrands wide"
      >
        Save
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
